<template>
  <div class="global-container">
    <h1>Paramètres</h1>
    <div class="drop-down-tab">
      <div class="button" @click="toggleGroupDropDownTab">
        <h3>Groupe</h3>
        <div v-if=groupDropDownTabOpen><span class="chevron fas fa-chevron-up"></span></div>
        <div v-else><span class="chevron fas fa-chevron-down"></span></div>
      </div>
      <div class="form-container" v-if="groupDropDownTabOpen">
        <GroupParametersForm class="form"/>
      </div>
    </div>
    <div class="drop-down-tab"  style="margin-top: 30px">
      <div class="button" @click="toggleBusinessDropDownTab">
        <h3>Gestionnaire</h3>
        <div v-if=businessDropDownTabOpen><span class="chevron fas fa-chevron-up"></span></div>
        <div v-else><span class="chevron fas fa-chevron-down"></span></div>
      </div>
      <div class="form-container" v-if="businessDropDownTabOpen">
        <BusinessGroupParametersForm  class="form" />
      </div>
    </div>
  </div>
</template>

<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
    library.add(faChevronUp);
    library.add(faChevronDown);
    import BusinessGroupParametersForm from "@/components/forms/BusinessGroupParametersForm.vue";
    import GroupParametersForm from "@/components/forms/GroupParametersForm.vue";

    export default {
        name: 'UserParametersView',
        components: {BusinessGroupParametersForm, GroupParametersForm},
        data: function () {
          return {
            groupDropDownTabOpen: false,
            businessDropDownTabOpen: false,
          }
        },
        methods: {
          toggleGroupDropDownTab() {
            this.businessDropDownTabOpen = false;
            this.groupDropDownTabOpen = !this.groupDropDownTabOpen;
          },
          toggleBusinessDropDownTab() {
            this.groupDropDownTabOpen = false;
            this.businessDropDownTabOpen = !this.businessDropDownTabOpen;
          }
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  .global-container
    display flex
    flex-direction column
    max-width 400px
    margin auto
    padding-bottom 50px
    h1
      padding-left 20px
      margin auto
      padding-top 25px
      padding-bottom 25px
    .chevron
      padding-left 10px

  .drop-down-tab
    border 1px rgba(THEME_COLOR_PRIMARY, 0.15) solid
    .button
      display flex
      align-items center
      justify-content center
      width calc(100% - 20px)
      background-color #ededff
      padding 10px
      height 30px
      text-align center
      cursor pointer
      h3
        margin 0
    .button:hover
      background-color #e1e1ff

  .form-container
    padding 10px
    .form
      max-width 400px
      margin auto
</style>
