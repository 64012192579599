<template>
    <div class="state-container" v-if="isRoleAuthorized">
      <CustomSwitchField :content=stateInput :toggleConditions=switchConditionCall />
      <CustomButton class="delete-button" @click="deleteClient" :params=buttons.delete style="margin-top: 20px" />
    </div>
  <ConfirmModal ref="stateConfirmModal" title="Changement d'état" text="Êtes-vous certain de vouloir changer le status ? Il est possible de le réactiver par la suite sans incidence." yes="Confirmer" no="Retour"/>
  <ConfirmModal ref="deleteConfirmModal" title="Suppression" text="Êtes-vous certain de vouloir supprimer le client ? Il ne sera plus possible de revenir en arrière ensuite." yes="Confirmer" no="Retour"/>
</template>

<script>
import {NotificationService} from "@/services/NotificationService";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomSwitchField from "@/components/forms/customs/customSwitchField/CustomSwitchField.vue";
import {ACTIVATION_STATUS_ENUM} from "@/models/activationStatusEnum";
import ConfirmModal from "@/components/modals/confirmModal.vue";
import {RightService} from "@/services/RightService";

export default {
  name: 'StateFormSection',
  props: ['stateInput', 'objectId', 'deleteCallback', 'controller'],
  components: {ConfirmModal, CustomSwitchField, CustomButton},
  data() {
    return {
      selfStateInput: this.stateInput,
      buttons:{
        delete: {text: "Supprimer", disabled : true}
      },
      isTerminateButtonDisplayed: false,
    };
  },
  computed: {
    isRoleAuthorized(){
      return RightService.isAtLeastModerator();
    }
  },
  watch: {
    "selfStateInput.value"(newValue) {
      this.buttons.delete.disabled = !(newValue === ACTIVATION_STATUS_ENUM.DEACTIVATED.value);
    }
  },
  methods: {
    async switchConditionCall(){
      if(!(this.stateInput.value === ACTIVATION_STATUS_ENUM.DEACTIVATED.value)){
        return this.$refs.stateConfirmModal.displayChoice( () => this.controller.deactivate(this.objectId));
      }else{
        return this.$refs.stateConfirmModal.displayChoice( () => this.controller.activate(this.objectId));
      }
    },
    async deleteClient(){
      const  res = await this.$refs.deleteConfirmModal.displayChoice(() => this.controller.delete(this.objectId));
      if (typeof res !== "boolean" && !res.errors) {
        (new NotificationService).addNotification("success", "Client supprimé");
        this.deleteCallback();
      }
    }
  }
}

</script>

<style lang="stylus" scoped>
@require '~/src/common/style/common.styl';
.state-container
  padding 10px
  padding-top 0
  margin 2px
  margin-top 20px
  border 1px rgba(THEME_COLOR_PRIMARY, 0.35) solid
  button.enabled
    background-color #8d1515
</style>
