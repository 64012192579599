<template>
  <div class="calendar">
    <div class="card" @click="callback(1)"><div class="number">1</div><div class="text"><p>Janvier</p></div></div>
    <div class="card" @click="callback(2)"><div class="number">2</div><div class="text"><p>Février</p></div></div>
    <div class="card" @click="callback(3)"><div class="number">3</div><div class="text"><p>Mars</p></div></div>
    <div class="card" @click="callback(4)"><div class="number">4</div><div class="text"><p>Avril</p></div></div>
    <div class="card" @click="callback(5)"><div class="number">5</div><div class="text"><p>Mai</p></div></div>
    <div class="card" @click="callback(6)"><div class="number">6</div><div class="text"><p>Juin</p></div></div>
    <div class="card" @click="callback(7)"><div class="number">7</div><div class="text"><p>Juillet</p></div></div>
    <div class="card" @click="callback(8)"><div class="number">8</div><div class="text"><p>Août</p></div></div>
    <div class="card" @click="callback(9)"><div class="number">9</div><div class="text"><p>Septembre</p></div></div>
    <div class="card" @click="callback(10)"><div class="number">10</div><div class="text"><p>Octobre</p></div></div>
    <div class="card" @click="callback(11)"><div class="number">11</div><div class="text"><p>Novembre</p></div></div>
    <div class="card" @click="callback(12)"><div class="number">12</div><div class="text"><p>Décembre</p></div></div>
  </div>
</template>

<script>
    export default {
      name: 'MonthChoice',
      props: ['callback']
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .calendar
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap 5% 5%
    max-width 900px
    width 100%
    height 80%

    .card
      max-height 5em
      background-color: white;
      color: THEME_COLOR_PRIMARY;
      font-weight 600
      padding-top: 0;
      padding-bottom: 20px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      transition: transform 0.3s, background-color 0.3s;
      box-shadow #8c8b8b 0 0 5px

      &:hover
        box-shadow #0b1016 0 0 10px
        transform scale(1.1)

      .number
        border-top-left-radius 5px
        border-top-right-radius 5px
        color white
        font-size 1.3em
        padding-left 20px
        padding-right 20px
        background-color #eb4150
        padding-top 0.5em
        height 1.5em
      .text
        display flex
        justify-content center
        align-items center
        height calc(100% - 20px)
        p
          font-size 1.3em
          margin 0

</style>
