<template>
    <div class="container-screen">
        <div class="screen">
          <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
              <p>
                Vous avez reçu par mail un lien (valable 15 minutes) de confirmation,
                cliquez sur ce lien afin d'activer votre compte.
                Si vous ne l'avez pas reçu, cliquez ici pour renvoyer un mail  :
              </p>
              <CustomTextField :content=inputs.email />
              <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
          </CustomForm>
        </div>
    </div>
</template>

<script>
 import CustomButton from "./customs/customButton/CustomButton.vue";
 import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faAt } from "@fortawesome/free-solid-svg-icons";
 import {UserController} from "@/controllers/user/UserController";
 import CustomForm from "@/components/forms/CustomForm.vue";
 library.add(faAt);

  export default {
      name: 'AskConfirmMail',
      components: {
        CustomForm,
          CustomTextField,
          CustomButton
      },
      data() {
          return {
            inputs: {
              email: {label: 'Email', value: '', type: 'email'},
            },
            buttons: {
              resend: {text: "Renvoyer"},
            },
            timer: 0
          };
      },
      methods: {
        startResendTimer() {
          this.timer = 15;
          this.buttons.submit.disabled = true;
          this.buttons.submit.text = `Renvoyer (${this.timer}s)`;
          const interval = setInterval(() => {
            if(this.timer > 0) {
              this.timer--;
              this.buttons.submit.text = `Renvoyer (${this.timer}s)`;
            }
          },1000);
          setTimeout(() => {
            clearInterval(interval);
            this.buttons.submit.text = `Renvoyer`;
            this.buttons.submit.disabled = false;
          }, 15000);
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => UserController.askConfirmMail(
            this.inputs.email.value
          ));
          if (!res.errors) {
            this.startResendTimer();
          }
        },
      }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl'
  @import 'responsive-form.styl'
</style>
