<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Bannisement d'un utilisateur</h2>
    <p><strong>Attention !</strong> Bannir un utilisateur n'est pas sans conséquence, les ressources que cet utilisateur a modifiées ou créées vous seront automatiquement transférés.</p>
    <p>Il n'est pas possible de revenir en arrière et le compte banni sera désactivé.</p>
    <p>Pour une solution temporaire, pensez plutôt à désactiver l'utilisateur.</p>
    <div class="button-container">
      <CustomForm ref="form" :inputs=[] :submit=buttons.submit>
        <CustomButton @click="submitForm" :params=buttons.submit />
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import {UserController} from "@/controllers/user/UserController";
import {NotificationService} from "@/services/NotificationService";
import CustomModal from "@/components/modals/CustomModal.vue";
import CustomForm from "@/components/forms/CustomForm.vue";
library.add(faXmark);

  export default {
      name: 'BanUserModal',
      props: ['callback'],
      components: {CustomForm, CustomModal, CustomButton},
      data() {
          return {
            userId: undefined,
            buttons:{
              submit: {text: "Confirmer"},
            }
          };
      },
      methods: {
        openModal(user) {
          this.$refs.modal.openModal();
          this.userId = user.id;
        },
        closeModal(event = false) {
          this.$refs.modal?.closeModal(event);
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => UserController.banUsers(
            this.userId
          ));
          if (!res.errors) {
            (new NotificationService).addNotification("success","Utilisateur banni");
            this.closeModal(true);
            this.callback();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .modal
      padding-bottom 40px
      height auto
      p
        padding-left 40px
        padding-right 40px
      .button-container
        display flex
        justify-content center
        padding-top 20px
        form
          align-items center
          button
            width 150px
</style>
