<template>
  <div class="global-container">
    <div class="desktop-only">
      <div class="design">
        <div class="text">
          <h1 style="font-size: 3.5em !important">Connectez-vous</h1>
          <h1 style="font-size: 2.7em !important">Et digitalisez votre entreprise</h1>
        </div>
      </div>
    </div>
    <RegisterForm/>
  </div>
</template>

<script>
    import RegisterForm from "../components/forms/RegisterForm.vue";

    export default {
        name: 'RegisterView',
        components: {
            RegisterForm
        }
    }
</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl'
  @import 'dualViews.styl'
</style>