import {BusinessGroupParamsController} from "@/controllers/business/BusinessGroupParamsController";

export class BusinessGroupParamService {

    static async get() {
        if(!localStorage.businessGroupParam){
            const resp = await BusinessGroupParamsController.get(localStorage.groupId);
            if(!resp.errors){
                localStorage.businessGroupParam = JSON.stringify(resp);
            }
        }
        return JSON.parse(localStorage.businessGroupParam);
    }

}
