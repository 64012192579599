import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class GroupController extends Controller {

    static post(body){
        return this.requestBuilder("POST",`/user/groups`, true, body);
    }

    static patch(groupId, body){
        return this.requestBuilder("PATCH",`/user/groups/${groupId}`, true, body);
    }

    static get(groupId){
        return this.requestBuilder("GET",`/user/groups/${groupId}`, true);
    }

}
