<template>
  <CustomTable ref="table" :filters="undefined">
    <template v-slot:table>
      <thead>
        <tr>
          <th>N° Attestation</th>
          <th>N° Quittance</th>
          <th class="only-tablet-table-cell">Effet</th>
          <th>Souscripteur</th>
          <th class="only-tablet-table-cell">Prime</th>
          <th>Reste</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="data && data.length > 0">
        <tr v-for="(business, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index">
          <td>{{business.attestationNumber ? decrypteData(business.attestationNumber) : '-'}}</td>
          <td>{{business.quittance ? decrypteData(business.quittance) : '-'}}</td>
          <td class="only-tablet-table-cell" style="max-width: 48px">{{ business.amendmentEffectiveDate ? (new Date(business.amendmentEffectiveDate)).toLocaleDateString("en-GB", formattedDate) : '-'}}</td>
          <td style="max-width: 70px; width: 30%">{{business.subscriber ? decrypteData(business.subscriber) : '-'}}</td>
          <td class="only-tablet-table-cell">{{ displayPrimeTTC(business.primeTTC) }}</td>
          <td style="font-weight: bold;" :class="[{[determinateColor(business.primeTTC - business.collected)]: true}]">{{ displayRest(business) }}</td>
          <td>
            <button class="button-icon-custom" @click="$refs.addInsuranceCollectionModal.openModal(business.id)"><span class="fas fa-money-bill-transfer"></span></button>
            <button v-if="business.manualEntry" class="button-icon-custom" @click="$refs.editInsuranceCollectionModal.openModal(business)"><span class="fas fa-pen"></span></button>
          </td>
        </tr>
      </tbody>
      <tbody v-else><tr class="empty-row"><td colspan="12">Aucun contrat enregitré</td></tr></tbody>
    </template>
  </CustomTable>
  <AddInsuranceCollectionModal ref="addInsuranceCollectionModal" :callback=loadData />
  <EditInsuranceBusinessModal ref="editInsuranceCollectionModal" :callback=loadData />
  <AddInsuranceBusinessModal ref="addInsuranceBusinessModal" :callback=loadData />
  <AddManualInsuranceBusinessModal ref="addManualInsuranceBusinessModal" :callback=loadData />
</template>

<script>
    import AddInsuranceBusinessModal from "@/components/modals/AddInsuranceBusinessModal.vue";
    import { InsuranceBusinessController } from "@/controllers/insurance/InsuranceBusinessController";
    import CustomTable from "@/components/CustomTable.vue";
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faMoneyBillTransfer, faList, faChevronLeft, faChevronRight, faPlus, faClock, faPen } from "@fortawesome/free-solid-svg-icons";
    import AddInsuranceCollectionModal from "@/components/modals/AddInsuranceCollectionModal.vue";
    import {decrypteData} from "@/utils";
    import AddManualInsuranceBusinessModal from "@/components/modals/AddManualInsuranceBusinessModal.vue";
    import EditInsuranceBusinessModal from "@/components/modals/EditInsuranceBusinessModal.vue";
    library.add(faChevronLeft, faPlus, faChevronRight, faMoneyBillTransfer, faList, faClock, faPen);

    export default {
        name: 'InsuranceRecoveryBusinessTable',
        props: ['month'],
        components: {
          EditInsuranceBusinessModal,
          AddManualInsuranceBusinessModal,
          AddInsuranceCollectionModal,
          CustomTable,
          AddInsuranceBusinessModal,
        },
        data: function () {
          return {
            data: [],
            formattedDateTime: {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit"
            },
            formattedDate: {
              day: "numeric",
              month: "numeric",
              year: "numeric"
            }
          }
        },
        mounted(){
          this.loadData();
        },
        methods: {
          decrypteData,
          determinateColor(amount){
            if(amount > 0){
              return "alert"
            }else if(amount === 0){
              return "classic"
            }else{
              return "warning"
            }
          },
          displayPrimeTTC(primeTTC){
            return primeTTC ? primeTTC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "-";
          },
          displayRest(business){
            return business.primeTTC && business.collected
                ? (business.primeTTC - business.collected).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                : "-";
          },
          async loadData(){
            this.$refs.table.startLoading();
            this.data = [];
            const resp = await InsuranceBusinessController.getByMonth(this.month);
            if(!resp.errors){
              for(let i=0; i < resp.length ;i++){
                this.data.push(resp[i]);
              }
            }
            this.$refs.table?.stopLoading();
          }
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  .alert
    color #dc0000
  .classic
    color auto
  .warning
    color #d58900

  .fa-file-pdf
    position absolute
    margin-top 15px
    height 15px

  .button-icon
    margin-right 10px

  .fa-clock
    position absolute
    height 12px
    margin-top -8px
</style>
