<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Modifier un client</h2>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <CustomSelectField :content=inputs.status />
        <CustomTextField :content=inputs.name />
        <CustomTextField :content=inputs.description />
        <CustomTextAreaField :content=inputs.address />
        <CustomTextField :content=inputs.contact />
        <div class="last-contact-container"><CustomTextField :content=inputs.lastContact /><CustomButton @click="lastContactNow()" :params=buttons.lastContactNow /></div>
        <StateFormSection :state-input=inputs.state :object-id=clientId :delete-callback=terminate :controller=ClientController />
        <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 20px" />
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import {NotificationService} from "@/services/NotificationService";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import {ClientController} from "@/controllers/business/ClientController";
import {encrypteData} from "@/utils";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import {ACTIVATION_STATUS_ENUM} from "@/models/activationStatusEnum";
import StateFormSection from "@/components/forms/StateFormSection.vue";
import CustomTextAreaField from "@/components/forms/customs/customTextAreaField/CustomTextAreaField.vue";
import {CLIENT_STATUS_ENUM_ARRAY} from "@/models/clientStatusEnum";
import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";

  export default {
      name: 'EditClientModal',
      computed: {
        ClientController() { return ClientController }
      },
      props: ['callback'],
      components: {
        CustomSelectField, CustomTextAreaField, StateFormSection, CustomModal, CustomForm, CustomTextField, CustomButton},
      data() {
          return {
            clientId: undefined,
            inputs: {
              name: {label: 'Nom', value: '', type: 'text', required: true},
              status: {label: 'Status', value: '', type: 'select', selectValues: CLIENT_STATUS_ENUM_ARRAY, required: true},
              description: {label: 'Description', value: '', type: 'text'},
              address: {label: 'Address', value: ''},
              contact: {label: 'Contact', value: '', type: 'text'},
              lastContact: {label: 'Dernier contact', value: '', type: 'date'},
              state: { value: undefined, left: ACTIVATION_STATUS_ENUM.DEACTIVATED, right: ACTIVATION_STATUS_ENUM.ACTIVATED, label: "Etat" },
            },
            buttons:{
              submit: {text: "Modifier"},
              lastContactNow: {text: "Maintenant"},
            },
            isTerminateButtonDisplayed: false
          };
      },
      methods: {
        openModal(client) {
          this.$refs.modal.openModal();
          this.buttons.submit.disabled = false;
          this.clientId = client.id
          this.inputs.name.value = client.name;
          this.inputs.status.value = client.status;
          this.inputs.description.value = client.description;
          this.inputs.address.value = client.address;
          this.inputs.contact.value = client.contact;
          this.inputs.lastContact.value = client.lastContact;
          this.inputs.state.value = client.state;
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        lastContactNow(){
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');

          this.inputs.lastContact.value = `${year}-${month}-${day}`;
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => ClientController.patch(this.clientId,{
            name : encrypteData(this.inputs.name.value),
            status : encrypteData(this.inputs.status.value),
            description : encrypteData(this.inputs.description.value),
            address : encrypteData(this.inputs.address.value),
            contact : encrypteData(this.inputs.contact.value),
            lastContact : encrypteData(this.inputs.lastContact.value)
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Client modifié");
            this.terminate();
          }
        }

      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .state-container
      padding 10px
      padding-top 0
      margin 2px
      margin-top 20px
      border 1px rgba(THEME_COLOR_PRIMARY, 0.35) solid
      button.enabled
        background-color #8d1515

    .last-contact-container
      display flex
      button
        height 52px
        margin-top 19px
        width 100px
        margin-left 10px
</style>
