import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class ClientController extends Controller {

     static get(clientId) {
         return this.requestBuilder("GET",`/business/clients/${clientId}`, true);
     }

     static getAll(urlParams = "") {
         return this.requestBuilder("GET",`/business/clients${urlParams}`, true);
     }
     static post(body) {
         return this.requestBuilder("POST",`/business/clients`, true, body);
     }

    static patch(clientId, body) {
        return this.requestBuilder("PATCH",`/business/clients/${clientId}`, true, body);
    }

    static delete(clientId) {
        return this.requestBuilder("DELETE",`/business/clients/${clientId}`, true);
    }

    static deactivate(clientId) {
        return this.requestBuilder("PATCH",`/business/clients/deactivate/${clientId}`, true);
    }

    static activate(clientId) {
        return this.requestBuilder("PATCH",`/business/clients/activate/${clientId}`, true);
    }

}
