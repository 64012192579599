<template>
  <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
    <CustomTextAreaField style="height: 150px" :content=inputs.defaultAddressPro />
    <CustomSelectField style="margin-top: 30px" :content=inputs.defaultDelay />
    <CustomTextField style="margin-top: 30px" :content=inputs.defaultUnitPrice />
    <div class="tva-container">
      <div class="numeroTVA"><CustomTextField :content=inputs.defaultTvaNumber /></div>
      <div class="TVA"><CustomTextField :content=inputs.defaultTva /></div>
    </div>
    <CustomTextAreaField style="height: 100px" :content=inputs.defaultBank />
    <CustomButton style="margin-top: 30px" @click="submitForm" :params=buttons.submit />
  </CustomForm>
</template>

<script>
    import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
    import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
    import router from "@/router";
    import {NotificationService} from "@/services/NotificationService";
    import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
    import CustomForm from "@/components/forms/CustomForm.vue";
    import {BusinessGroupParamsController} from "@/controllers/business/BusinessGroupParamsController";
    import CustomTextAreaField from "@/components/forms/customs/customTextAreaField/CustomTextAreaField.vue";

    export default {
        name: 'BusinessGroupParametersForm',
        components: {
          CustomTextAreaField,
          CustomForm,
          CustomSelectField,
          CustomButton,
          CustomTextField,
        },
        data: function () {
            return {
                options: [],
                inputs: {
                  defaultAddressPro: {label: 'Address pro', value: ''},
                  defaultTvaNumber: {label: 'Numéro de TVA', value: '', type: 'text'},
                  defaultTva: {label: 'TVA', value: 20, type: 'number'},
                  defaultBank: {label: 'Coordonnées bancaires', value: ''},
                  defaultDelay: {label: 'Délai de paiement', value: '', type: 'select', selectValues: [{label:"45", value: 45}, {label:"30", value: 30}, {label:"15", value: 15}]},
                  defaultUnitPrice: {label: 'Prix unitaire', value: '', type: 'number'},
                },
                buttons:{
                  submit: {text: "Mettre à jour"},
                },
                dropDownTabOpen: false,
            }
        },
        async mounted(){
          this.$refs.form.startLoading();
          const resp = await BusinessGroupParamsController.get(localStorage.groupId)
          if(!resp.errors){
            this.options = resp.options;
            this.inputs.defaultAddressPro.value = resp.defaultAddressPro;
            this.inputs.defaultTvaNumber.value = resp.defaultTvaNumber;
            this.inputs.defaultTva.value = resp.defaultTva;
            this.inputs.defaultBank.value = resp.defaultBank;
            this.inputs.defaultDelay.value = resp.defaultDelay;
            this.inputs.defaultUnitPrice.value = resp.defaultUnitPrice;
          }
          this.$refs.form?.stopLoading();
        },
      methods: {
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => BusinessGroupParamsController.patch(localStorage.groupId, {
            defaultAddressPro: this.inputs.defaultAddressPro.value,
            defaultTvaNumber: this.inputs.defaultTvaNumber.value,
            defaultTva: this.inputs.defaultTva.value,
            defaultBank: this.inputs.defaultBank.value,
            defaultDelay: this.inputs.defaultDelay.value,
            defaultUnitPrice: this.inputs.defaultUnitPrice.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success","Paramètres de groupe modifié");
            router.push("/group-parameters");
          }
        }
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .tva-container
    display flex
    .numeroTVA
      margin-right 1em
    .TVA
      width 5em

  .separator
    margin-top 15px
    height 2px
    background-color THEME_COLOR_PRIMARY_FADDED
    opacity 0.6

  .options-container
    .line
      display flex
      align-items center
      .line1
        width 15px
        height 1px
        background-color #d1d1d4
      .line2
        height 1px
        background-color #d1d1d4
        width 100%
      .title
        color #444
        font-weight 600
        margin 0
        margin-bottom 5px
        margin-top 5px
        padding-left 5px
        padding-right 5px
    .option-container
      border solid 1px
      margin-top 5px
      margin-bottom 5px
      border-color #d1d1d4
      background-color #f8f8f8
      .option-name
        text-align center
        margin 5px
        color #a8a8a8
        font-weight 600
        font-size 15px
</style>
