<template>
  <div class="global-container">
    <h1>Légal</h1>
    <h2>Mentions légales</h2>
      <h3>Exploitant</h3>
      <p>La Micro Entreprise Citron Digital (Franck Web), dont le siège social est situé 8 rue canto laouzeto 31140 AUCAMVILLE, dont le numéro de SIRET est le 88495522000030, enregistré par Franck Besson, président, représentant légal actuellement en fonction, domicilié en cette qualité audit siège.</p>

      <h3>Hébergeur</h3>
      <p>La Société OVH, SAS au capital de 10 069 020€, dont le siège social est situé 2 rue Kellermann au 59100 à Roubaix, immatriculée au registre du commerce et des sociétés de Lille Métropole sous le n°424 761 419, représentée par Henryk KLABA, président, représentant légal actuellement en fonction, domicilié en cette qualité audit siège.</p>

      <CGUContent/>
      <CGVContent/>
      <CookiesContent/>
  </div>
</template>

<script>
    import CGUContent from "@/components/CGUContent.vue";
    import CookiesContent from "@/components/CookiesContent.vue";
    import CGVContent from "@/components/CGVContent.vue";

    export default {
        name: 'LegalView',
      components: {CGVContent, CookiesContent, CGUContent},
    }
</script>

<style lang="stylus" scoped>
@require '~/src/common/style/common.styl';
.global-container
  h1
    text-align center
  padding-left 50px
  padding-right 50px
  padding-bottom 50px

</style>