import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class BusinessGroupParamsController extends Controller {

    static patch(groupId, body){
        return this.requestBuilder("PATCH",`/business/group-params/${groupId}`, true, body);
    }

    static get(groupId){
        return this.requestBuilder("GET",`/business/group-params/${groupId}`, true);
    }

}
