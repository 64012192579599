import router from "@/router";

/**
 * Permet de gérer tout ce qui est lié à l'utilisateur
 */
export class RestService {

    /**
     * Setup for make this class a Singleton
     */
    constructor() {
        if (RestService.exists) {
            return RestService.instance;
        }
        RestService.instance = this;
        RestService.exists = true;
        return this;
    }

    async restRequest(method, url ,isAuth = false, requestBody, headers) {
        let res = undefined;
        let fetchParams = {};

        if(isAuth){
            headers.Authorization =`Bearer ${localStorage.token}`;
        }

        if(requestBody && headers["Content-Type"] && headers["Content-Type"] === "application/json"){
            fetchParams.body = JSON.stringify(requestBody);
        }else{
            fetchParams.body = requestBody;
        }

        fetchParams.headers = headers;
        fetchParams.method = method;

        try{
            res = await (await fetch(url, fetchParams)).json();
            if(!res){
                console.log("Error on rest request : undefined");
                return {errors:[{"code":"NO_RESPONSE_FROM_SERVER","message":"Le serveur ne répond pas"}]};
            }
            if(res.errors) {
                for (let i = 0; i < res.errors.length; i++) {
                    if (res.errors[i].code === "TOKEN_EXPIRED_ERROR") {
                        localStorage.clear();
                        router.push("/login");
                        return {errors:[{"code":"TOKEN_EXPIRED_ERROR","message":"Votre session à expirée"}]};
                    } else if (res.errors[i].code === "INVALID_TOKEN") {
                        localStorage.clear();
                        router.push("/login");
                        return {errors:[{"code":"INVALID_TOKEN","message":"Votre session à rencontrée un problème"}]};
                    }
                }
            }
        }catch(e){
            console.log("Error on rest request : " + e);
            return {errors:[{"code":"NO_RESPONSE_FROM_SERVER","message":"Le serveur ne répond pas"}]};
        }

        return res;
    }

}
