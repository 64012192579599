<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Edition d'un utilisateur</h2>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form"  :inputs=inputs :submit=buttons.submit>
        <CustomSwitchField :content=inputs.state />
        <CustomSelectField style="margin-top: 30px" :content=inputs.role />
        <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
import CustomSwitchField from "@/components/forms/customs/customSwitchField/CustomSwitchField.vue";
import { ACTIVATION_STATUS_ENUM } from "@/models/activationStatusEnum";
import {UserController} from "@/controllers/user/UserController";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
library.add(faXmark);

  export default {
      name: 'EditUserModal',
      props: ['callback'],
      computed: {
        ACTIVATION_STATUS_ENUM() {
          return ACTIVATION_STATUS_ENUM
        }
      },
      components: {CustomModal, CustomForm, CustomSelectField, CustomButton, CustomSwitchField},
      data() {
          return {
            userId: undefined,
            inputs: {
              state: { value: undefined, left: ACTIVATION_STATUS_ENUM.ACTIVATED, right: ACTIVATION_STATUS_ENUM.DEACTIVATED, label: "Etat" },
              role: {label: 'Rôle', value: '', type: 'select', selectValues: [
                  {label: "Administrateur", value: "ADMIN"},
                  {label: "Modérateur", value: "MODERATOR"},
                  {label: "Normal", value: "CLASSIC"}]},
            },
            buttons:{
              submit: {text: "Valider"},
            }
          };
      },
      methods: {
        openModal(user) {
          this.$refs.modal.openModal();
          this.userId = user.id;
          this.inputs.state.value = user.state;
          this.inputs.role.value = user.role;
        },
        closeModal(event = false) {
          this.$refs.modal?.closeModal(event);
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => UserController.patch(this.userId,{
            role : this.inputs.role.value,
            state : this.inputs.state.value
          }));
          if (!res.errors) {
            this.closeModal(true);
            this.callback();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
</style>
