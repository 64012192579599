<template>
    <div>
      <!--<h2>Conditions générales de vente</h2>
      <h3>Article 1 : Objet</h3>-->
    </div>
</template>

<script>
  export default {
      name: 'CGVContent',
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl';
</style>
