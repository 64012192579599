<template>
  <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
    <h1>Cet email est déjà vérifié.</h1>
  </div>
</template>

<script>
    export default {
        name: 'MailAlreadyCheckView',
    }
</script>