export const BUSINESS_STATUS_ENUM = {
    IN_PROGRESS: { label: 'En cours', value: "IN_PROGRESS" },
    CREATION: { label: 'En rédaction', value: "CREATION" },
    ACCEPTED: { label: 'Accepté', value: "ACCEPTED" },
    DONE: { label: 'Terminé', value: "DONE" },
    PAID: { label: 'Payée', value: "PAID" },
    PAID_AND_DECLARED: { label: 'Payée et déclarée', value: "PAID_AND_DECLARED" },
    REFUSED: { label: 'Refusé', value: "REFUSED" },
    SENT: { label: 'Envoyé', value: "SENT" },
    READY_TO_SEND: { label: 'Prêt à l\'envoie', value: "READY_TO_SEND" },
    ABORTED: { label: 'Annulé', value: "ABORTED" },
}

export const BUSINESS_STATUS_ENUM_ARRAY= [
    BUSINESS_STATUS_ENUM.IN_PROGRESS,
    BUSINESS_STATUS_ENUM.CREATION,
    BUSINESS_STATUS_ENUM.ACCEPTED,
    BUSINESS_STATUS_ENUM.DONE,
    BUSINESS_STATUS_ENUM.PAID,
    BUSINESS_STATUS_ENUM.PAID_AND_DECLARED,
    BUSINESS_STATUS_ENUM.REFUSED,
    BUSINESS_STATUS_ENUM.SENT,
    BUSINESS_STATUS_ENUM.READY_TO_SEND,
    BUSINESS_STATUS_ENUM.ABORTED,
]