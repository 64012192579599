<template>
  <div class="table">
    <CustomLoader ref="loader"/>
    <div class="table-header-container">
      <slot name="header-options"/>
      <SearchArray v-if=!disableSearch :callback=callback :inputs=filters ref="searchArray" />
    </div>
    <table>
      <slot name="table"/>
    </table>
    <PaginationArray :callback=callback :defaultIndex=defaultIndex :defaultPages=defaultItemsPerPages id="paginationArray" ref="paginationArray" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLemon } from "@fortawesome/free-solid-svg-icons";
import PaginationArray from "@/components/PaginationArray.vue";
import SearchArray from "@/components/SearchArray.vue";
import CustomLoader from "@/components/CustomLoader.vue";
library.add(faLemon);

/* eslint-disable vue/no-mutating-props */
export default {
  name: 'CustomTable',
  components: {
    CustomLoader,
    SearchArray,
    PaginationArray
  },
  props: ['callback', 'filters', 'defaultItemsPerPages', 'defaultIndex', 'disableSearch'],
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    if(this.$refs.paginationArray) {
      this.$refs.paginationArray.initWithURLParams();
    }
    if(this.$refs.searchArray) {
      this.$refs.searchArray.initWithURLParams();
    }
  },
  methods: {
    buildURLParams(){
      let paramsBuilder = "?";

      // Call url params builder
      paramsBuilder += this.$refs.paginationArray ? this.$refs.paginationArray.urlParamsBuilder() : "";
      paramsBuilder += this.$refs.searchArray ? this.$refs.searchArray.urlParamsBuilder() : "";

      // If no params, return nothing else remove the last &
      return paramsBuilder === "?" ? "" : paramsBuilder;
    },
    initWithURLParams(){
      if(this.$refs.paginationArray){
        this.$refs.paginationArray.initWithURLParams();
      }
    },
    startLoading(){
      this.$refs.loader.startLoading();
    },
    stopLoading(){
      this.$refs.loader?.stopLoading();
    }
  }
}

</script>

<style lang="stylus" scoped>
@import '~/src/common/style/common.styl'

.table-header-container
  display flex
  margin-bottom 10px
  button
    margin-right 10px
  div
    width 100%

.table
  position relative
</style>
