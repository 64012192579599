import {getValueFromRole, RoleEnum} from "@/models/roleEnum";

/**
 * Permet de gérer tout ce qui est lié à l'utilisateur
 */
export class RightService {

    /**
     * Setup for make this class a Singleton
     */
    constructor() {
        if (RightService.exists) {
            return RightService.instance;
        }
        RightService.instance = this;
        RightService.exists = true;
        return this;
    }

    static isAtLeastModerator(){
        return getValueFromRole(localStorage.userRole) >= getValueFromRole(RoleEnum.MODERATOR);
    }

    static canManageUser(user){
        return getValueFromRole(localStorage.userRole) > getValueFromRole(user.role);
    }

}
