<template>
  <h1><span>Dashboard / </span>Utilisateurs</h1>
  <UserTable/>
</template>

<script>
    import UserTable from "@/components/tables/UserTable.vue";

    export default {
        name: 'UserListView',
        components: {
          UserTable
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  h1 span
      color: THEME_COLOR_PRIMARY_FADDED
</style>