<template>
  <div class="global-container">
    <div class="container"><InsuranceRecoveryBusinessTable v-if="month" :month="month" /></div>
    <div class="container"><InsuranceRecoveryPart/></div>
  </div>
</template>

<script>
    import InsuranceRecoveryPart from "@/components/InsuranceRecoveryCompanyPart.vue";
    import InsuranceRecoveryBusinessTable from "@/components/tables/InsuranceRecoveryBusinessTable.vue";

    export default {
      name: 'InsuranceRecovery',
      components: {InsuranceRecoveryBusinessTable, InsuranceRecoveryPart},
      props: ['month'],
      methods:{
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .global-container
    display flex
    gap 5%
    justify-content center
    width 100%
    height 100%

    .container
      padding-top 20px
      width 100%

    :deep(.table)
      width 100%
</style>
