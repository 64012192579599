<template>
  <div style="display: flex">
    <button @click="displaySearch" class="button-icon"><span class="fas fa-magnifying-glass"></span></button>
    <div style="width: 10px"></div>
    <button v-if=inputs @click="displayAdvancedSearch" class="button-icon only-desktop-block"><span class="fas fa-magnifying-glass-plus"></span></button>
    <div v-if=inputs style="width: 10px"></div>
    <div class="global-container">
      <div v-if=inputs class="search-container only-desktop-flex" :class="[{'active': isAdvancedSearchActivated}]">
        <CustomForm :inputs=selfInputs :submit=buttons.submit>
          <CustomSelectField style="width: 300px" :content=selfFiltersInputs.field />
          <div style="width: 20px"></div>
          <CustomSelectField style="width: 150px" :content=selfInputs.operator />
          <div style="width: 20px"></div>
          <CustomTextField :content=selfInputs.variable class="text-field"/>
          <div style="width: 28px"></div>
          <CustomSelectField style="width: 300px" :content=selfFiltersInputs.sort />
          <div style="width: 20px"></div>
          <CustomSelectField style="width: 300px" :content=selfInputs.order />
          <div style="width: 20px"></div>
          <CustomButton style="width: 200px" @click="callback" :params=buttons.submit />
        </CustomForm>
      </div>
      <div class="search-container" :class="[{'active': isSearchActivated}]" >
        <CustomTextField :content=selfInputs.search class="text-field" />
      </div>
    </div>
  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import {faMagnifyingGlass, faMagnifyingGlassPlus} from "@fortawesome/free-solid-svg-icons";
  import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
  import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
  import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
  import {OPERATOR_ENUM} from "@/models/operatorEnum";
  import CustomForm from "@/components/forms/CustomForm.vue";
  library.add(faMagnifyingGlass);
  library.add(faMagnifyingGlassPlus);

  export default {
    name: 'SearchArray',
    components: {CustomForm, CustomButton, CustomSelectField, CustomTextField},
    props: ['callback', 'inputs'],
    data: function() {
      return {
        buttons:{
          submit: {text: "Rechercher"},
        },
        selfInputs: {
          search: {label: 'Filtrer', value: '', type: 'text', readOnly: false},
          operator: {value: OPERATOR_ENUM.GREATER_THAN.value, type: 'select', selectValues: [OPERATOR_ENUM.EQUAL, OPERATOR_ENUM.GREATER_THAN, OPERATOR_ENUM.MINUS_THAN], readOnly: true},
          order: {value: "ASCENDING", type: 'select', selectValues: [{label:"Croissant", value: "ASCENDING"}, {label:"Décroissant", value: "DESCENDING"}], readOnly: true},
          variable: {label: 'Valeur', value: '', type: 'text', readOnly: true},
        },
        isAdvancedSearchActivated: false,
        isSearchActivated: false,
        selfFiltersInputs: this.inputs
      }
    },
    watch: {
      "selfFiltersInputs.field.value"(el) {
        if(["amendmentEffectiveDate","createdAt","expirationDate"].includes(el)){
          this.selfInputs.variable.type = "date";
        }else if(["primeTTC"].includes(this.selfFiltersInputs.field.value)){
          this.selfInputs.variable.type = "number";
        }else{
          this.selfInputs.variable.type = "text";
        }

        this.selfInputs.variable.readOnly = false;
        this.selfInputs.operator.readOnly = false;
        this.selfInputs.variable.readOnly = false;
      },
      "selfFiltersInputs.sort.value"() {
        this.selfInputs.order.readOnly = false;
      }
    },
    methods: {
      displayAdvancedSearch(){
        this.isAdvancedSearchActivated = !this.isAdvancedSearchActivated;
        this.isSearchActivated = false;
      },
      displaySearch(){
        this.isSearchActivated = !this.isSearchActivated;
        this.isAdvancedSearchActivated = false;
      },
      // Warn ! If some changes are done in data from template for diplay purposes,
      // we are filtering on initial value here !
      simpleFilter(list){
        if(this.selfInputs.search.value && this.selfInputs.search.value !== ''){
          return list.filter(o => {
            for (const k in o) {
              if(o[k] && o[k].toString().toLowerCase().includes(this.selfInputs.search.value.toLowerCase())){
                return true;
              }
            }
          })
        }else{
          return list;
        }
      },
      /**
       * Init component data with those given in URL
       */
      initWithURLParams(){
        const query = this.$route.query;
        if(query.field){ this.selfFiltersInputs.field.value = query.field }
        if(query.operator){ this.selfInputs.operator.value = query.operator }
        if(query.variable){ this.selfInputs.variable.value = query.variable }
        if(query.sort){ this.selfFiltersInputs.sort.value = query.sort }
        if(query.order){ this.selfInputs.order.value = query.order }
      },
      /**
       * Build component data for URL format
       * @returns {string}
       */
      urlParamsBuilder(){
        let paramsBuilder = "";
        if(this.selfInputs.variable.value){
          paramsBuilder += `variable=${this.selfInputs.variable.value}&field=${this.selfFiltersInputs.field.value}&operator=${this.selfInputs.operator.value}&`;
        }
        if(this.selfFiltersInputs?.sort.value !== undefined){
          paramsBuilder += `sort=${this.selfFiltersInputs.sort.value}&order=${this.selfInputs.order.value}&`;
        }
        return paramsBuilder;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  //@media only screen and (min-width: DESKTOP_SIZE)
  //@media only screen and (min-width: TABLET_SIZE)

  .global-container
    position relative
    overflow-y visible
    overflow-x clip
    width 100%

  .global-container
    .search-container
      width 100%
      visibility hidden
      position absolute
      left -100%
      transition-duration 250ms

      form
        flex-direction row

      .text-field
        position relative
        top -15px

  .global-container .search-container.active
    visibility visible
    transition-duration 250ms
    transform translateX(100%)
</style>
