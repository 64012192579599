<template>
  <!-- 8px are sommes of default margin and padding of text input -->
  <div :class="{'required': selfContent.required, 'editable': !selfContent.readOnly}" style="width: calc(100% - 8px)">
    <label v-if="selfContent.label">{{selfContent.label}}</label>
    <input v-model="selfContent.value"
           :type=selfContent.type
           :step=selfContent.step
           class="input"
           placeholder="..."
           autocomplete="on"
           @input="selfContent.error=false"
           :class="{'bad_input': selfContent.error}"
           @keyup.enter="login"
           :readonly="selfContent.readOnly"/>
  </div>
</template>

<script>
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faLock } from "@fortawesome/free-solid-svg-icons";

 library.add(faLock);

  export default {
      name: 'CustomTextField',
      props: ['content'],
      data: function () {
          return {
              selfContent: this.content,
          };
      },
      mounted(){

      },
      methods: {

      }
  }

</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @require 'style.styl';
</style>
