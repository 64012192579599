<template>
  <form>
    <CustomLoader ref="loader"/>
    <slot></slot>
  </form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLemon } from "@fortawesome/free-solid-svg-icons";
import CustomLoader from "@/components/CustomLoader.vue";
library.add(faLemon);

/* eslint-disable vue/no-mutating-props */
export default {
  name: 'CustomForm',
  props: ['inputs', 'submit'],
  components: {
    CustomLoader
  },
  data() {
    return {
      formLoading: false
    };
  },
  watch: {
    "inputs": {
      deep: true,
      handler(){
        let isDisabled = false;
        for(const key in this.inputs){
          if(this.inputs[key].required && (this.inputs[key].value === '' || this.inputs[key].value === undefined)){
            isDisabled = true;
          }
        }
        this.submit.disabled = isDisabled;
      }
    }
  },
  methods: {
    loadSubmit(state){
      this.submit.loading = state;
      this.submit.disabled = state;
    },
    async submitForm(promise){
      const self = this;
      return new Promise(function (resolve) {
        self.loadSubmit(true);
        promise().then((res) => {
          self.loadSubmit(false);
          resolve(res);
        });
      });
    },
    startLoading(){
      this.$refs.loader.startLoading();
    },
    stopLoading(){
      this.$refs.loader?.stopLoading();
    }
  }
}

</script>

<style lang="stylus" scoped>
@import '~/src/common/style/common.styl'
@import 'responsive-form.styl'

form
  position relative
  display flex
  flex-direction column
  width 100%
  height 100%
</style>
