<template>
    <button v-if="params && !params.loading"
            type="button"
            :class="{enabled: !params.disabled}"
            :disabled='params.disabled'>
      <span >{{params.text}}</span>
    </button>
    <button v-else-if="params"
            type="button"
            :class="{enabled: !params.disabled}"
            :disabled='params.disabled'>
      <span class="fas fa-lemon"></span>
    </button>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faLemon } from "@fortawesome/free-solid-svg-icons";
  library.add(faLemon);

  export default {
      name: 'CustomButton',
      props: ['params']
  }
</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    //@media only screen and (min-width: DESKTOP_SIZE)
    //@media only screen and (min-width: TABLET_SIZE)

    @keyframes ckw
      0%
          transform rotate(0deg)
      100%
          transform rotate(360deg)

    .fa-lemon
      height 60%
      color THEME_COLOR_PRIMARY !important
      opacity 0.6
      animation-name ckw
      animation-duration 1s
      animation-iteration-count infinite
      transform-origin 50% 50%

    button
      width 100%
      height 55px
      background-color #d0d0d0
      color THEME_COLOR_PRIMARY_OPPOSITE
      border none
      border-radius 3px
      font-weight bold

    //---- Enabled button -----
    .disabled
      background-color #d0d0d0 !important
      cursor none

    .enabled
      background-color THEME_COLOR_PRIMARY
      cursor pointer

    .enabled:hover
      box-shadow 0 0 5px 0 #252525
</style>
