<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Téléverser un contrat</h2>

    <!-- Drag and drop -->
    <input style="display: none" type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept=".pdf" />
    <div v-if="!this.filelist.length || this.filelist.length === 0" class="dragleave" style="height: 200px; margin: 20px" @dragover="dragover" @dragleave="dragleave" @drop="drop" >
        <div class="container" style="pointer-events: none;height: 100%;" id="container">
          <label class="line2" style="text-align: center;font-weight: bold;">Déplacer un fichier ici pour téléverser une attestation</label>
          <label class="line3" style="text-align: center;font-weight: bold;">OU</label>
          <div class="line4" style="display: flex; justify-content: center;"><button class="button-icon" id="button" style="pointer-events: auto; width: 130px" @click="this.$refs.file.click()">Cliquez ici</button></div>
        </div>
        <div class="dragover-hide arrow" id="arrow" style="pointer-events: none"><i class="fa-solid fa-up-long"></i></div>
    </div>

    <!-- List files -->
    <div v-else style="margin: 20px">
      <p style="padding-left: 15px;font-weight: bold;">Les fichiers suivants seront téléversés :</p>
      <ul style="padding-right: 10px" v-cloak>
        <li style="display: flex; align-items: center" v-for="(file) in filelist" :key="file.name + file.status">
          <label style="font-size: 15px">{{ file.name }}</label>
          <button v-if="file.status === ''" class="button-icon-custom" style="margin-right: 0;margin-left: auto;" type="button" title="Remove file" @click="remove(filelist.indexOf(file))">
            <span style="height: 15px; width: 15px" class="fas fa-xmark"></span>
          </button>
          <div v-if="file.status === 'waiting'" class="icon"><span>...</span></div>
          <div v-if="file.status === 'success'" class="icon"><span class="fas fa-check success"></span></div>
          <div v-if="file.status === 'error'" class="icon"><span class="fas fa-exclamation error"></span></div>
        </li>
      </ul>
      <!-- Buttons -->
      <div style="display: flex; justify-content: center;">
        <div style="display: flex; justify-content: center; width: 300px">
          <CustomButton v-if="!isTerminateButtonDisplayed" @click="postInsuranceBusiness(this.filelist)" :params="buttons.submit" style="margin-top: 15px;" />
          <CustomButton v-else @click="terminate" :params="buttons.terminate" style="margin-top: 15px;" />
          <div style="width: 15px"></div>
          <CustomButton @click="filelist = []; buttons.submit.disabled = false;" :params="buttons.back" style="margin-top: 15px" />
        </div>
      </div>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faPlus, faUpLong, faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import {NotificationService} from "@/services/NotificationService";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import {InsuranceBusinessController} from "@/controllers/insurance/InsuranceBusinessController";
import CustomModal from "@/components/modals/CustomModal.vue";
library.add(faXmark);
library.add(faPlus);
library.add(faCheck);
library.add(faUpLong);
library.add(faExclamation);

  export default {
      name: 'AddInsuranceBusinessModal',
      props: ['callback'],
      components: {CustomModal, CustomButton},
      data() {
          return {
            documentName: "test",
            filelist: [], // Store our uploaded files
            buttons:{
              back: {text: "Retour"},
              submit: {text: "Valider"},
              terminate: {text: "Terminer"},
            },
            isTerminateButtonDisplayed: false,
          };
      },
      methods: {
        openModal() {
          this.$refs.modal.openModal();
          this.buttons.submit.disabled = false;
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        /* DRAG AND DROP SECTION */
        onChange() {
          this.filelist = [];
          const selectedFiles = [...this.$refs.file.files];

          if (selectedFiles.length > 0) {
            for(let i=0; i < selectedFiles.length ;i++){
              if(selectedFiles[i].type === "application/pdf"){
                // Set the status that can be used later for know if the file is successfully send or not
                selectedFiles[i].status = '';
                this.filelist.push(selectedFiles[i]);
              }else{
                // Warn when file ot a pdf
                (new NotificationService).addNotification("warning", "Le document doit être un pdf : " + selectedFiles[i].name);
              }
            }
          }
        },
        remove(i) {
          this.filelist.splice(i, 1);
        },
        dragover(event) {
          event.preventDefault();

          // Change area style
          event.currentTarget.classList.add('dragover');
          event.currentTarget.classList.remove('dragleave');

          // Hide text
          document.getElementById("container").classList.add("dragover-hide");
          document.getElementById("container").classList.remove("dragover-show");

          // Hide button
          document.getElementById("button").classList.add("dragover-hide");
          document.getElementById("button").classList.remove("dragover-show");

          // Show arrow
          document.getElementById("arrow").classList.add("dragover-show");
          document.getElementById("arrow").classList.remove("dragover-hide");
        },
        dragleave(event) {

          // Change area style
          event.currentTarget.classList.add('dragleave');
          event.currentTarget.classList.remove('dragover');
          event.currentTarget.classList.remove('dragover-hide');

          // Show text
          document.getElementById("container").classList.add("dragover-show");
          document.getElementById("container").classList.remove("dragover-hide");

          // Show button
          document.getElementById("button").classList.add("dragover-show");
          document.getElementById("button").classList.remove("dragover-hide");

          // Hide arrow
          document.getElementById("arrow").classList.add("dragover-hide");
          document.getElementById("arrow").classList.remove("dragover-show");
        },
        drop(event) {
          event.preventDefault();
          this.$refs.file.files = event.dataTransfer.files;
          this.onChange(); // Trigger the onChange event manually
          if(!this.filelist || this.filelist.length === 0){
            this.dragleave(event);
          }
        },
        /* DRAG AND DROP SECTION */
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        async postInsuranceBusiness(fileList) {

          this.buttons.submit.disabled = true;

          // Set status as waiting
          for(let i=0; i < fileList.length ;i++){
            fileList[i].status = "waiting";
            this.$forceUpdate();
          }

          // Cpt for know when all async request finishes
          let cptFinish = 0;

          // Post insurance business
          for(let i=0; i < fileList.length ;i++){

            // Set the pdf in a form data
            let data = new FormData();
            data.append('pdf', fileList[i]);
            data.append('key', localStorage.encryptionKey);
            // Post
            const resp = await InsuranceBusinessController.post(data)
            if (!resp.errors){
              fileList[i].status = "success";
              (new NotificationService).addNotification("success","Contrat téléversé : " + fileList[i].name);
            }else{
                if(resp.errors) {
                    fileList[i].status = "error";
                }
            }
            this.$forceUpdate();
            cptFinish ++;
            if(cptFinish === fileList.length){ this.isTerminateButtonDisplayed = true; }
          }

        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    //@media only screen and (min-width: DESKTOP_SIZE)
    //@media only screen and (min-width: TABLET_SIZE)

    .center
      display flex
      justify-content center
      align-items center

    .container
      display grid
      grid-template-columns 1fr
      grid-template-rows repeat(5, 1fr)
      grid-column-gap 0px
      grid-row-gap 0px

    .line1 { grid-area: 1 / 1 / 2 / 2 }
    .line2 { grid-area: 2 / 1 / 3 / 2 }
    .line3 { grid-area: 3 / 1 / 4 / 2 }
    .line4 { grid-area: 4 / 1 / 5 / 2 }
    .line5 { grid-area: 5 / 1 / 6 / 2 }

    .error
      color red

    .success
      color green

    .icon
      height 15px
      width 15px
      display flex
      justify-content center
      padding 1px 6px
      margin-right 0
      margin-left auto

    // DRAG AND DROP
    .arrow
      position relative
      top -200px
      height 100%
      display flex
      justify-content center
      align-items center
      pointer-events none
      .fa-up-long
        width 50%
        height 50%
        color #002100

    [v-cloak]
      display none

    .dragleave
      background-color #e7e7e7
      border dashed #002100

    .dragover
      background-color #f5f5f5
      border dashed #002100

    .dragover-hide
      opacity 0
      pointer-events none !important

    .dragover-show
      opacity 1
      pointer-events auto
    // DRAG AND DROP
</style>
