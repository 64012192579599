import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class BusinessController extends Controller {

     static getAll(urlParams) {
         return this.requestBuilder("GET",`/business/businesses/table/all${urlParams}`, true);
     }

     static get(id) {
         return this.requestBuilder("GET",`/business/businesses/${id}`, true);
     }

     static post(body) {
         return this.requestBuilder("POST",`/business/businesses`, true, body);
     }

     static patch(id, body) {
         return this.requestBuilder("PATCH",`/business/businesses/${id}`, true, body);
     }

}
