<template>
    <div>
      <h2>Conditions générales d'utilisation</h2>
      <h3>Article 1 : Objet</h3>
      <p>Les présentes Conditions Générales d'Utilisation (CGU) encadrent juridiquement l’utilisation des services du site Citron Digital, opéré par la société Franck Web. L'accès et l'utilisation de ce site impliquent l'acceptation des présentes CGU.</p>
      <h3>Article 2 : Propriété intellectuelle</h3>
      <p>Les marques, logos et contenus du site Citron Digital sont protégés par le Code de la propriété intellectuelle et le droit d'auteur. Toute reproduction ou utilisation à des fins commerciales ou publicitaires des contenus nécessite une autorisation préalable du site.</p>
      <h3>Article 3 : Responsabilité</h3>
      <p>Le site n'est pas responsable des éventuels dommages causés par l'utilisation du site ou par des virus informatiques.</p>
      <h3>Article 4 : Utilisation des cookies</h3>
      <p>Le site Citron Digital peut utiliser des cookies pour améliorer l'expérience utilisateur. En utilisant le site, l'utilisateur consent à l'utilisation de cookies conformément à la politique de confidentialité du site.</p>
      <!-- Responsabilité de l'utilisateur -->
      <h3>Article 5 : Responsabilité de l'utilisateur concernant ses données</h3>
      <p>L'utilisateur est responsable de l'exactitude et de la légalité des données qu'il fournit sur le site Citron Digital. Il s'engage à ne pas violer les droits de tiers et à respecter la réglementation en vigueur.</p>
      <h3>Article 6 : Responsabilité de l'utilisateur pour les utilisateurs invités</h3>
      <p>L'utilisateur est responsable des actions de tout utilisateur dont il invite à utiliser les services de Citron Digital.</p>
      <!-- Gestion des données -->
      <h3>Article 7 : Consentement de l'utilisateur</h3>
      <p>En utilisant les services de Citron Digital, l'utilisateur consent à la collecte, au stockage et à l'utilisation de ses données conformément à la politique de confidentialité du site.</p>
      <h3>Article 8 : Collecte et utilisation des données sensibles</h3>
      <p>L'application web Citron Digital collecte et stocke des données sensibles dans le cadre de ses fonctionnalités et services. Les données sensibles comprennent, mais ne se limitent pas à, des informations personnelles telles que les noms d'utilisateur, les adresses électroniques, les mots de passe, les données financières, ou toute autre information identifiant de manière spécifique un individu.</p>
      <p>Pour la création du compte de l’utilisateur, la collecte des informations au moment de l’inscription sur le site est nécessaire et obligatoire. Conformément à la loi n°78-17 du 6 janvier relative à l’informatique, aux fichiers et aux libertés, la collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.</p>
      <p>Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l’utilisateur dispose du droit d’accéder, de rectifier, de supprimer et d’opposer ses données personnelles. L’exercice de ce droit s’effectue par :

        Le formulaire de contact ;
        Son espace client
      </p>
      <h3>Article 9 : Protection et sécurité des données sensibles</h3>
      <p>Citron Digital met en place des mesures de sécurité pour protéger les données sensibles contre tout accès non autorisé ou toute divulgation.</p>
      <h3>Article 10 : Divulgation des données sensibles</h3>
      <p>Citron Digital ne divulgue pas les données sensibles à des tiers sans consentement de l'utilisateur, sauf en cas de nécessité légale.</p>
      <h3>Article 11 : Accès restreint aux données densibles</h3>
      <p>Citron Digital n'accède pas aux données sensibles des utilisateurs, sauf en cas de nécessité (avec accord des utilisateurs en question) pour assurer le bon fonctionnement des services.</p>
      <h3>Article 12 : Conservation des données densibles</h3>
      <p>Les données sensibles sont conservées pendant la durée nécessaire pour fournir les services de l'application Web et se conformer aux obligations légales. Une fois que les données ne sont plus nécessaires, elles sont supprimées de manière sécurisée ou anonymisées, sauf indication contraire de la loi.</p>
      <h3>Article 13 : Droits de l'utilisateur</h3>
      <p>L'utilisateur a le droit d'accéder à ses données sensibles, de les corriger, de les mettre à jour ou de les supprimer. Pour exercer ces droits ou pour toute question concernant la protection des données sensibles, l'utilisateur peut contacter Citron Digital via le lien informations situé en bas de page.</p>
      <!-- CGU -->
      <h3>Article 14 : Changements de la politique de confidentialité</h3>
      <p>Cette politique de confidentialité peut être modifiée pour refléter les changements dans les pratiques de collecte, d'utilisation et de protection des données sensibles. Les utilisateurs seront informés de tout changement important par le biais d'une notification sur l'application Web ou par d'autres moyens appropriés.</p>
      <h3>Article 15 : Durée du contrat</h3>
      <p>Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’utilisateur.</p>
      <h3>Article 16 : Droit applicable et juridiction compétente</h3>
      <p>Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux.</p>
    </div>
</template>

<script>
  export default {
      name: 'CGUContent',
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl';
</style>
