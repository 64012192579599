<template>
  <CustomTable ref="table" :callback=reloadCallback :filters=filtersInputs>
    <template v-slot:table>
      <thead>
        <tr>
          <th>Date de création</th>
          <th>Souscripteur</th>
        </tr>
      </thead>
      <tbody v-if="data && data.length > 0">
        <tr v-for="(client, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index">
          <td>{{(new Date(client.createdAt)).toLocaleDateString("en-GB", formattedDate)}}</td>
          <td>{{ decrypteData(client.subscriber) }}</td>
        </tr>
      </tbody>
      <tbody v-else><tr style="background-color: #40513b14;font-weight: bold"><td colspan="12" style="text-align: center">Aucun contrat enregitré</td></tr></tbody>
    </template>
  </CustomTable>
</template>

<script>
    import {InsuranceClientController} from "@/controllers/insurance/InsuranceClientController";
    import CustomTable from "@/components/CustomTable.vue";
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faMoneyBillTransfer, faList, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
    import {decrypteData} from "@/utils";
    library.add(faChevronLeft, faList, faChevronRight, faMoneyBillTransfer);

    export default {
        name: 'InsuranceClientTable',
        components: {
          CustomTable
        },
        data: function () {
          return {
            data: [],
            formattedDate: {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit"
            },
            filtersInputs: {
              field: {label: 'Champs', value: '', type: 'select', selectValues: [
                  { label: "Date de création", value: "createdAt" },
                  { label: "Souscripteur", value: "subscriber" },
                ]},
              sort: {label: 'Trié par', value: '', type: 'select', selectValues: [
                  { label: "Date de création", value: "createdAt" },
                  { label: "Souscripteur", value: "subscriber" },
                ]},
            }
          }
        },
        mounted(){
          this.loadData();
        },
        methods: {
          decrypteData,
          reloadCallback(){
            window.location.href=`/insurance-clients${this.$refs.table.buildURLParams()}`;
          },
          async loadData(){
            this.$refs.table.startLoading();
            this.data = [];
            const resp = await InsuranceClientController.getAll(this.$refs.table.buildURLParams());
            if(!resp.errors){
              for(let i=0; i < resp.length ;i++){
                this.data.push(resp[i]);
              }
            }
            this.$refs.table?.stopLoading();
          }
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
</style>