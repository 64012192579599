<template>
  <CustomTable ref="table" :callback=reloadCallback :filters=filtersInputs>
    <template v-slot:header-options>
      <button @click="$refs.addUserModal.openModal()" class="button-icon"><span class="fas fa-user-plus"></span></button>
    </template>
    <template v-slot:table>
      <thead>
        <tr>
          <th>Identité</th>
          <th>Email</th>
          <th>Rôle</th>
          <th>Groupe</th>
          <th>Validité</th>
          <th>Etat</th>
          <th>Date de création</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="data && data.length > 0">
      <tr v-for="(user, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ getLabelOfUserRole(user.role) }}</td>
          <td>{{getLabelOfGroupName(user.groupName)}}</td>
          <td>{{ user.isVerify ? "Oui" : "Non" }}</td>
          <td>{{ getLabelOfActivationStatus(user.state) }}</td>
          <td>{{ (new Date(user.createdAt)).toLocaleDateString("en-GB", formattedDate) }}</td>
          <td style="min-width: 25px">
            <button v-if="canManageUser(user)" class="button-icon-custom" @click="$refs.editUserModal.openModal(user)">
              <span class="fas fa-user-gear"></span>
              <!--<span class="fas fa-triangle-exclamation"></span>-->
            </button>
            <button v-if="canManageUser(user)" class="button-icon-custom" @click="$refs.banUserModal.openModal(user)">
              <span class="fas fa-user-large-slash"></span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else><tr class="empty-row"><td colspan="12">Aucun utilisateur enregitré</td></tr></tbody>
    </template>
  </CustomTable>
  <EditUserModal ref="editUserModal" :callback=loadData />
  <BanUserModal ref="banUserModal" :callback=loadData />
  <AddUserModal ref="addUserModal" :callback=loadData />
</template>

<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import EditUserModal from "@/components/modals/EditUserModal.vue";
    import BanUserModal from "@/components/modals/BanUserModal.vue";
    import AddUserModal from "@/components/modals/AddUserModal.vue";
    import {UserController} from "@/controllers/user/UserController";
    import { faMoneyBillTransfer, faList, faChevronLeft, faTriangleExclamation,
      faChevronRight, faUserLock, faUserCheck, faUserGear, faUserLargeSlash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
    import CustomTable from "@/components/CustomTable.vue";
    import {RightService} from "@/services/RightService";
    library.add(faChevronLeft, faChevronRight, faMoneyBillTransfer, faList, faUserLock,
        faUserCheck, faUserGear, faTriangleExclamation, faUserLargeSlash, faUserPlus);

    export default {
        name: 'UserTable',
        components: {
          CustomTable,
          AddUserModal,
          BanUserModal,
          EditUserModal,
        },
        data: function () {
          return {
            data: [],
            formattedDate: {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit"
            },
            filtersInputs: {
              field: {label: 'Champs', value: '', type: 'select', selectValues: [
                  { label: "Identité", value: "name" },
                  { label: "Email", value: "email" },
                  { label: "Date de création", value: "createdAt" },
                ]},
              sort: {label: 'Trié par', value: '', type: 'select', selectValues: [
                  { label: "Identité", value: "name" },
                  { label: "Email", value: "email" },
                  { label: "Rôle", value: "role" },
                  { label: "Validité", value: "isVerify" },
                  { label: "Etat", value: "state" },
                  { label: "Date de création", value: "createdAt" },
                ]},
            }
          }
        },
        mounted(){
          this.loadData();
        },
        methods: {
          reloadCallback(){
            window.location.href=`/users${this.$refs.table.buildURLParams()}`;
          },
          getLabelOfUserRole(role){
            if(role === "ADMIN"){ return "Administrateur" }
            else if(role === "CLASSIC"){ return "Normal" }
            else if(role === "MODERATOR"){ return "Modérateur" }
            else if(role === "NONE"){ return "Pas de rôle" }
            else{ return "Inconnu"}
          },
          getLabelOfGroupName(name){
            if(name === "super_admin"){ return "Super Administrateurs" }
            else if(name === "newer"){ return "Nouveaux" }
            else if(name === "unknown"){ return "Inconnus" }
            else{ return name}
          },
          getLabelOfActivationStatus(activationStatus){
            if(activationStatus === "ACTIVATED"){ return "Activé" }
            else if(activationStatus === "DEACTIVATED") { return "Désactivé" }
          },
          async loadData() {
            this.$refs.table.startLoading();
            const resp = await UserController.getAll(this.$refs.table.buildURLParams())
            this.data = !resp.errors ? resp : [];
            this.$refs.table?.stopLoading();
          },
          canManageUser(user){
            return RightService.canManageUser(user);
          }
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .fa-triangle-exclamation
    position absolute
    height 14px !important
    margin-left -19px
    margin-top -2px
    color #de8900

  .button-icon
    margin-right 10px
</style>