<template>
  <div class="container_notification">
    <div class="notification" :class="[{'showed': showed,'hided': !showed}]">
      <div class="container_icon" :class="this.type" style="grid-area: 1 / 1 / 2 / 2;"><div class="left_bar" :class="[type]"></div><i :class="icon" style="height: 25px"></i></div>
      <div class="container_text" style="grid-area: 1 / 2 / 2 / 10;"><span>{{text}}</span></div>
      <div class="container_icon" style="grid-area: 1 / 10 / 2 / 11; cursor: pointer;z-index: 2" @click="quitNotification(id)"><i class="fa-solid fa-times" style="color: #878787 !important;"></i></div>
      <div class="loader"></div>
    </div>

  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faCircleCheck, faCircleInfo, faTriangleExclamation, faTimes , faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
  import { NotificationService } from "../../services/NotificationService";
  library.add(faCircleQuestion);
  library.add(faCircleInfo);
  library.add(faCircleCheck);
  library.add(faTriangleExclamation);
  library.add(faTimes);

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Notification',
    props: ['type', 'text', 'id', "date"],
    data: function() {
      return {
        showed: true,
        icon: "",
        selfTimeLeft: "15s",
        selfTimeLeftPercent: 0
      }
    },
    mounted(){
      switch(this.type) {
        case "success": {
          this.icon = "fa-solid fa-circle-check";
          break;
        }
        case "info": {
          this.icon = "fa-solid fa-circle-info";
          break;
        }
        case "warning": {
          this.icon = "fa-solid fa-triangle-exclamation";
          break;
        }
        case "danger": {
          this.icon = "fa-solid fa-triangle-exclamation";
          break;
        }
        default: {
          this.icon = "fa-solid fa-circle-question";
          break;
        }
      }
      this.getTimeleft(this.date);
    },
    methods: {
      getTimeleft(notifDate){
        const timeleft = (new Date() - new Date(notifDate)) / 1000;
        this.selfTimeLeft = (15-timeleft).toFixed(3)+"s";
        this.selfTimeLeftPercent = (timeleft/15).toFixed(3);
      },
      /**
       * Quit the notification and remove it from the list
       *
       * @param id
       */
      quitNotification(id){
        // Remove the notification from service
        (new NotificationService).removeNotification(id);
        // Launch animation for remove it from CSS
        this.showed = false;
        // Reload notification list from parent
        setTimeout(() => this.$parent.reloadNotification(), 250)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @require 'style.styl';

  @keyframes slideInFromLeft {
    0% {
      transform-origin: left;
      transform: scale(v-bind('selfTimeLeftPercent'),1);
    }
    100% {
      transform-origin: left;
      transform: scale(1,1);
    }
  }

  .loader {
    animation: v-bind('selfTimeLeft') ease-out 0s 1 slideInFromLeft;
  }
</style>
