<template>
  <div class="parent">
    <div class="div1" style="display: flex;justify-content: flex-end;">
      <CustomSelectField style="margin-bottom:1px;margin-top:auto;width: 70px" :content=paginationInputs.pages />
    </div>
    <div class="div2" style="display: flex; justify-content: center">
      <button @click="changeIndex(-1)" :class="[{'read-only': index <= 0}]" class="button-icon"><span class="fas fa-chevron-left"></span></button>
      <div style="width: 10px"></div>
      <button class="button-icon" style="cursor: auto">{{index}}</button>
      <div style="width: 10px"></div>
      <button @click="changeIndex(1)" class="button-icon"><span class="fas fa-chevron-right"></span></button>
    </div>
    <div class="div3"></div>
  </div>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
  import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
  library.add(faChevronLeft, faChevronRight);

  export default {
    name: 'PaginationArray',
    components: { CustomSelectField } ,
    props: ['callback', 'defaultIndex', 'defaultPages', 'pagesReadOnly'],
    data: function() {
      return {
        index: this.defaultIndex ? this.defaultIndex : 0,
        paginationInputs: {
          pages: {value: Number(this.defaultPages) ? Number(this.defaultPages) : 25, type: 'select', selectValues: [
              {label:"5", value: 5},
              {label:"10", value: 10},
              {label:"25", value: 25},
              {label:"50", value: 50},
              {label:"100", value: 100}
            ], readOnly: this.pagesReadOnly ? this.pagesReadOnly : false},
        },
        isNextReloadCancel: false
      }
    },
    watch: {
      "paginationInputs.pages.value"() {
        if(!this.isNextReloadCancel){
          this.callback()
        }
        this.isNextReloadCancel = false;
      }
    },
    methods: {
      setPages(pages){
        if(this.paginationInputs.pages.value !== pages){
          this.isNextReloadCancel = true;
        }
        this.paginationInputs.pages.value = pages;
      },
      changeIndex(index){
        if(this.index + index >= 0) {
          this.index += index
          this.callback();
        }
      },
      /**
       * Init component data with those given in URL
       */
      initWithURLParams(){
        const query = this.$route.query;
        if(query.pages){ this.setPages(Number(query.pages)) }
        if(query.index){ this.index = Number(query.index) }
      },
      /**
       * Init component data with those given in params
       */
      init(index, pages){
        if(pages !== undefined){ this.setPages(pages) }
        if(index !== undefined){ this.index = Number(index) }
      },
      /**
       * Build component data for URL format
       * @returns {string}
       */
      urlParamsBuilder(){
        return `pages=${this.paginationInputs.pages.value}&index=${this.index}&`;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  //@media only screen and (min-width: DESKTOP_SIZE)
  //@media only screen and (min-width: TABLET_SIZE)

  .parent
    display grid
    grid-template-columns repeat(5, 1fr)
    grid-template-rows 1fr
    grid-column-gap 0
    grid-row-gap 0
    margin-top 10px


  .div1 { grid-area: 1 / 5 / 2 / 6 }
  .div2 { grid-area: 1 / 2 / 2 / 5 }
  .div3 { grid-area: 1 / 1 / 2 / 2 }

  .read-only
    background-color #d0d0d0 !important
    cursor auto !important
</style>
