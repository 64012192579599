<template>
  <div class="modal-overlay" ref="modalOverlay" v-show="this.isDisplayed">
    <div class="modal">
      <div style="display: flex;justify-content: flex-end">
        <button class="button-icon-custom" @click="closeModal()"><span class="fas fa-xmark"></span></button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomModal',
    props: ['callback'],
    components: {},
    data() {
        return {
          isDisplayed: false,
          isMouseDownOnOuterDiv: undefined,
        }
    },
    mounted() {
      this.$refs.modalOverlay.addEventListener("mousedown", (event) => {
        this.isMouseDownOnOuterDiv = this.isClickOnOuterDiv(event);
      });
      this.$refs.modalOverlay.addEventListener("mouseup", (event) => {
        if (this.isMouseDownOnOuterDiv && this.isClickOnOuterDiv(event)) {
          this.closeModal();
        }
        this.isMouseDownOnOuterDiv = false;
      });
    },
    methods: {
      isClickOnOuterDiv(event){
        // Check if the clicked element is the same as the outer div
        const clickedElement = event.target;
        const outerDiv = this.$refs.modalOverlay;
        return clickedElement === outerDiv;
      },
      openModal() {
        // prevent scroll from happening
        const body = document.body;
        if (body) body.style.overflow = "hidden";
        this.isDisplayed = true;
      },
      closeModal() {
        // reset scroll
        const body = document.body;
        if (body) body.style.overflow = "auto";
        this.isDisplayed = false;
      }
    }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .modal-overlay {
      position fixed
      z-index 20
      top 0
      bottom 0
      left 0
      right 0
      display flex
      justify-content center
      align-items center
      background-color rgba(0, 0, 0, 0.60)
    }

    .modal
      background-color THEME_COLOR_FOREGROUND
      height auto
      width 500px
      padding 5px
      padding-bottom 30px
      border-radius 10px
</style>
