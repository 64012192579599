<template>
  <h1><span>Dashboard / </span>Contrats</h1>
  <BusinessTable/>
</template>

<script>
    import BusinessTable from "@/components/tables/BusinessTable.vue";

    export default {
      name: 'BusinessListView',
      components: {
        BusinessTable
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  h1 span
      color: THEME_COLOR_PRIMARY_FADDED
</style>
