<template>
  <div class="global-container">
    <div class="desktop-only">
      <div class="design">
        <div class="text">
          <h1 style="font-size: 3.5em !important">Connectez-vous</h1>
          <h1 style="font-size: 2.7em !important">Et digitalisez votre entreprise</h1>
        </div>
      </div>
    </div>
    <SendConfirmMail v-if="this.$route.params.token"/>
    <AskConfirmMail v-else/>
  </div>
</template>

<script>
    import AskConfirmMail from "@/components/forms/AskConfirmMail.vue";
    import SendConfirmMail from "@/components/forms/SendConfirmMail.vue";

    export default {
        name: 'ConfirmMailView',
        components: {
          SendConfirmMail,
          AskConfirmMail,
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @require 'dualViews.styl';
</style>