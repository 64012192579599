import jwt_decode from "jwt-decode";

/**
 * Permet de gérer tout ce qui est lié à l'utilisateur
 */
export class JWTService {

    jwtTokenSecret = "Hm44FfGP5GMVfYqW7kCrzP7VS8HGLNXNj5by3qbAmLU1r412Ryj4m7EeXYdhLPy";

    /**
     * Setup for make this class a Singleton
     */
    constructor() {
        if (JWTService.exists) {
            return JWTService.instance;
        }
        JWTService.instance = this;
        JWTService.exists = true;
        return this;
    }

    setJwtTokenSecret(jwtTokenSecret) {
        this.jwtTokenSecret = jwtTokenSecret;
    }

    verifyJWT(token) {
        if (token === null || token === undefined) throw 'Token is null';
        return jwt_decode(token, this.jwtTokenSecret);
    }

}
