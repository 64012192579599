<template>
  <div class="carousel-container">
    <div class="carousel">
      <div class="carousel-inner" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
        <div v-for="(slotContent, index) in slots" :key="index" class="carousel-item">
          <slot :name="`slide-${index}`"></slot>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: 'CustomCarousel',
  data() {
    return {
      currentSlide: 0,
      slots: [],
    };
  },
  mounted() {
    this.slots = this.$slots ? Object.keys(this.$slots) : [];
  },
  methods: {
    goToSlide(index) {
      this.currentSlide = index;
    },
    nextSlide() {
      // eslint-disable-next-line vue/require-slots-as-functions
      this.currentSlide = (this.currentSlide + 1) % this.$slots.default().length;
    },
    prevSlide() {
      // eslint-disable-next-line vue/require-slots-as-functions
      this.currentSlide = (this.currentSlide - 1 + this.$slots.default().length) % this.$slots.default().length;
    },
  },
};
</script>


<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .carousel-container
    width 100%
    height "calc(100% - %s)" % (FOOTER_HEIGHT)

  .carousel
    overflow: hidden
    width: 100%
    height: 100%
    position: relative

  .carousel-inner
    display: flex
    transition: transform 0.5s ease-in-out
    width: 100%
    height: 100%

  .carousel-item
    min-width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
</style>


