<template>
  <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
    <h1>Vous êtes déconnecté</h1>
  </div>
</template>

<script>
  export default {
    name: 'LogoutView',
    mounted(){
      localStorage.clear();
      // Send a notification to the navbar for a rerender it
      window.dispatchEvent(new CustomEvent('isConnectedChange', { detail: { isConnected : false } }));
    }
  }
</script>