/**
 * RightEnum Schema
 *
 * Defines fields
 */
export const RoleEnum = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    MODERATOR: "MODERATOR",
    CLASSIC: "CLASSIC",
    NONE: "NONE",
}

export function getValueFromRole(role) {
    return role === RoleEnum.CLASSIC ? 1 :
           role === RoleEnum.MODERATOR ? 2 :
           role === RoleEnum.ADMIN ? 3 :
           role === RoleEnum.SUPER_ADMIN ? 4 : 0;
}

