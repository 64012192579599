<template>
  <CustomModal ref="modal" class="confirm-modal">
    <h2 style="text-align: center">{{ title }}</h2>
    <div style="padding-left: 30px; padding-right: 30px">
      <p>{{ text }}</p>
      <CustomForm ref="form" :submit=buttons.submit>
        <CustomButton @click=submitNo :params=buttons.back style="margin-top: 10px; margin-right: 30px"/>
        <CustomButton @click=submitYes :params=buttons.submit style="margin-top: 10px"/>
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";

  export default {
    name: 'ConfirmModal',
    props: ['title', 'text', 'yes', 'no'],
    components: {CustomModal, CustomForm, CustomButton},
    data() {
        return {
          documentName: "test",
          buttons:{
            back: {text: this.no},
            submit: {text: this.yes}
          },
          isTerminateButtonDisplayed: false,
          choice: undefined,
          choicePromise: undefined /* Promise to retrieve to have the answer of confirm modal */
        };
    },
    methods: {
        openModal() {
          this.$refs.modal.openModal();
          this.choice = undefined;
          this.choicePromise = new Promise((resolve) => {
            const interval = setInterval(() => {
              if (this.choice !== undefined) {
                clearInterval(interval);
                resolve(this.choice);
              }
            }, 100);
          });
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback();
        },
        submitYes(){
          this.choice=true;
        },
        submitNo(){
          this.choice=false;
        },
        /**
         * @param promise
         * @returns true ou false celon le choix ou le résultat de la promise s'il y en a une
         */
        async displayChoice(promise){
          const self = this;
          return new Promise(function (resolve) {
            self.openModal();
            self.choicePromise.then((choice) => {
              if(choice){
                if(promise){
                  // Yes choice + promise
                  self.$refs.form.submitForm(() => promise()).then((res) => {
                    self.closeModal(true);
                    resolve(res);
                  });
                }else{
                  // Yes choice
                  self.closeModal(true);
                  resolve(true);
                }
              }else{
                // False choice
                self.closeModal(true);
                resolve(false);
              }
            });
          });
        }
      }
  }

</script>

<style lang="stylus">
  .confirm-modal .modal
    width 400px
</style>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .title-number-container
      display flex
      .title
        margin-right 1em
      .number
        width 100px !important

    .tva-container
      display flex
      .numeroTVA
        margin-right 1em
      .TVA
        width 100px !important

    .separator
      margin-top 15px
      height 2px
      background-color THEME_COLOR_PRIMARY_FADDED
      opacity 0.6

    form
      display flex
      flex-direction row
</style>
