//import CryptoJS from 'crypto-js';

import {BUSINESS_TYPE_ENUM} from "@/models/businessTypeEnum";
import {BUSINESS_STATUS_ENUM} from "@/models/businessStatusEnum";

/**
 * Share common functions
 */
export function debounce (fn, delay, timeoutID) {
    clearTimeout(timeoutID)
    const args = arguments;
    const that = this;
    return setTimeout(function () {
        fn.apply(that, args)
    }, delay)
}

// Crypto part
//const ivKey = process.env.VUE_APP_CRYPTO_IV_KEY;

export function encrypteData(data) {
    /*if(!localStorage.encryptionKey){
        console.log("No encryption key");
        throw Error("No encryption key");
    }
    if (data) {
        const key = CryptoJS.PBKDF2(localStorage.encryptionKey, 'salt', { keySize: 256/32, iterations: 100 });
        const iv = CryptoJS.enc.Utf8.parse(ivKey); // Convert string to WordArray
        const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    }else{
        return data;
    }*/
    return data;
}

export function convertISOToDate(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont de 0 à 11
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function decrypteData(data) {
    /*if(!localStorage.encryptionKey){
        console.log("No encryption key");
        throw Error;
    }
    if (data) {
        const key = CryptoJS.PBKDF2(localStorage.encryptionKey, 'salt', { keySize: 256/32, iterations: 100 });
        const iv = CryptoJS.enc.Utf8.parse(ivKey);
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(data) }, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }else{
        return data;
    }*/
    return data;
}

export function possibleBusinessStatusOnCreation(type) {
    // Common ones
    const values = [
        BUSINESS_STATUS_ENUM.ABORTED,
        BUSINESS_STATUS_ENUM.READY_TO_SEND,
        BUSINESS_STATUS_ENUM.SENT,
        BUSINESS_STATUS_ENUM.CREATION,
    ];

    if(type === BUSINESS_TYPE_ENUM.QUOTE.value){
        // Only on quote type
        values.push(BUSINESS_STATUS_ENUM.IN_PROGRESS);
        values.push(BUSINESS_STATUS_ENUM.ACCEPTED);
        values.push(BUSINESS_STATUS_ENUM.DONE);
        values.push(BUSINESS_STATUS_ENUM.REFUSED);
    }else if(type === BUSINESS_TYPE_ENUM.INVOICE.value){
        // Only on invoice type
        values.push(BUSINESS_STATUS_ENUM.PAID);
    }

    return values;
}

export function possibleBusinessStatusSelect(status, type) {
    const values = [];

    // CREATION
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.READY_TO_SEND.value) {
        values.push(BUSINESS_STATUS_ENUM.CREATION);
    }

    // SENT on invoice
    if(type === BUSINESS_TYPE_ENUM.INVOICE.value && status === BUSINESS_STATUS_ENUM.READY_TO_SEND.value) {
        values.push(BUSINESS_STATUS_ENUM.SENT);
    }

    // SENT on quote
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.READY_TO_SEND.value) {
        values.push(BUSINESS_STATUS_ENUM.SENT);
    }

    // READY_TO_SEND
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.CREATION.value) {
        values.push(BUSINESS_STATUS_ENUM.READY_TO_SEND);
    }

    // ABORTED
    if(status !== BUSINESS_STATUS_ENUM.PAID.value && status !== BUSINESS_STATUS_ENUM.IN_PROGRESS.value && status !== BUSINESS_STATUS_ENUM.DONE.value){
        values.push(BUSINESS_STATUS_ENUM.ABORTED);
    }

    // IN_PROGRESS
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.ACCEPTED.value) {
        values.push(BUSINESS_STATUS_ENUM.IN_PROGRESS);
    }

    // ACCEPTED
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.SENT.value) {
        values.push(BUSINESS_STATUS_ENUM.ACCEPTED);
    }

    // DONE
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.IN_PROGRESS.value) {
        values.push(BUSINESS_STATUS_ENUM.DONE);
    }

    // REFUSED
    if(type === BUSINESS_TYPE_ENUM.QUOTE.value && status === BUSINESS_STATUS_ENUM.SENT.value) {
        values.push(BUSINESS_STATUS_ENUM.REFUSED);
    }

    // PAID
    if(type === BUSINESS_TYPE_ENUM.INVOICE.value && status === BUSINESS_STATUS_ENUM.SENT.value) {
        values.push(BUSINESS_STATUS_ENUM.PAID);
    }

    // PAID_AND_DECLARED
    if(type === BUSINESS_TYPE_ENUM.INVOICE.value && status === BUSINESS_STATUS_ENUM.PAID.value) {
        values.push(BUSINESS_STATUS_ENUM.PAID_AND_DECLARED);
    }

    // Current status
    values.push(BUSINESS_STATUS_ENUM[status]);

    return values;
}

export function timeAgo(date) {
    const now = new Date();
    const diffInMs = now - new Date(date);
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays >= 1) {
        return `${diffInDays} jours`;
    } else {
        return `${diffInHours}h`;
    }
}