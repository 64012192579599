<template>
  <div :class="[{'required': selfInputs.required, 'editable': !selfInputs.readOnly, 'pure-mode' : selfInputs.lightMode, checked : isChecked, unchecked : !isChecked}]">
    <label class="label">{{ selfInputs.label }}</label>
    <div class="toggle-container">
      <label id="left">{{ selfInputs.left.label }}</label><!-- valeur uncheck -->
      <div class="switch-container">
        <label class="switch" >
          <input id="checkbox" type="checkbox" @click.prevent="toggle" v-model=isChecked :readonly="selfInputs.readOnly">
          <span class="slider round"></span>
        </label>
      </div>
      <label id="right" >{{ selfInputs.right.label }}</label><!-- valeur check -->
    </div>
  </div>
</template>

<script>
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faLock } from "@fortawesome/free-solid-svg-icons";

 library.add(faLock);

  export default {
      name: 'CustomSwitchField',
      props: ['content', 'toggleConditions'],
      data: function () {
          return {
            selfInputs: this.content,
            isChecked: false
          };
      },
      watch: {
        "selfInputs.value"(e) {
          this.isChecked = e === this.selfInputs.right.value;
        },
      },
      mounted(){
        this.isChecked = this.selfInputs.value === this.selfInputs.right.value;
      },
      methods: {
        async toggle(item) {
          // toggleConditions is used for confirmation modal
          if(this.toggleConditions){
            const res = await this.toggleConditions();
            if(!(res)){
              return
            }
          }
          if(this.selfInputs.readOnly){ return }
          this.selfInputs.value = this.selfInputs.value === this.selfInputs.right.value ? this.selfInputs.left.value : this.selfInputs.right.value;
          item.active = !item.active;
        }
      }
  }

</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @require 'style.styl';
</style>
