<template>
  <nav class="navbar" ref="navbar">

    <div class="container-nav">

      <img class="navbar-img" :src=this.logo_img height="50" alt="Logo">

      <ul class="navbar-text">
        <li style="display: inline;" v-for="(route, index) in link_routes" :key="index">
          <router-link class="nav-link" :to=route.route>{{ route.name }}</router-link>
        </li>
      </ul>
      <div class="navbar-button">

        <button v-on:click="toggle_user_menu" type="button">
          <span style="color: white"><i class="fa-solid fa-user fa-2x"></i>
            <span v-if="this.connected" style="color: green"><i class="fa-solid fa-circle-check"></i></span>
            <span v-else style="color: dimgrey"><i class="fa-solid fa-circle-xmark"></i></span>
          </span>
        </button>

        <button class="navbar-burger" v-on:click="toggle_menu" type="button">
          <span style="color: white"><i class="fa-solid fa-bars fa-2x collapsible"></i></span>
        </button>
      </div>

    </div>

    <div class="link_menu shield"></div>
    <div class="link_menu collapsed_content"
         :class="{'showed': link_menu,'hided': !link_menu}"
         :style="{'--size': (this.link_routes.length+2) + 'em'}">
      <ul class="navbar-nav">
        <li v-for="(route, index) in link_routes" :key="index">
          <router-link @click="toggle_menu" class="nav-link" :to=route.route>{{ route.name }}</router-link>
        </li>
      </ul>
    </div>

    <div class="user_menu shield"></div>
    <div class="user_menu collapsed_content"
         :class="{'showed': user_menu,'hided': !user_menu}"
         :style="{'--size': (this.user_routes.length+2) + 'em'}">
      <ul class="navbar-nav">
        <li v-for="(route, index) in user_routes" :key="index">
          <router-link @click="toggle_user_menu" class="nav-link" :to=route.route>{{ route.name }}</router-link>
        </li>
      </ul>
    </div>

    <Notification v-for="(notif, index) in notifications" :key="notif" :type=notif.type :text=notif.text :id=notif.id :date=notif.date :style="{'margin-top': (index*75) + 'px'}"/>

  </nav>

</template>

<script>
  import Notification from "../../components/notification/Notification"
  import { NotificationService } from "../../services/NotificationService"
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faBars, faUser, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
  library.add(faUser);
  library.add(faBars);
  library.add(faCircleCheck);
  library.add(faCircleXmark);

  export default {
    name: 'CustomNav',
    components: {
      Notification
    },
    props: ['logo_img', 'link_routes', 'user_routes', 'connected'],
    data: function() {
      return {
        link_menu: false,
        user_menu: false,
        notifications: [],
      };
    },
    mounted() {
      // Pour tester les notifications
     /* if(!localStorage.notifications || JSON.parse(localStorage.notifications).length < 5){
        console.log("notif set");
        let notifications = [
          {text:"Notification de danger.", type: "danger", id: 1},
          {text:"Ceci est une information", type: "info", id: 2},
          {text:"Bravo c'est un success !", type: "success", id: 3},
          {text:"Oula, attention :s", type: "warning", id: 4},
          {text:"Hum ... petit problème ?", type: "sucess", id: 5}
        ];
        localStorage.notifications  = JSON.stringify(notifications);
      }*/

      let self = this;
      (new NotificationService).setCallBackReload(function (){self.notifications = NotificationService.getNotification()});
      this.notifications = NotificationService.getNotification();

      // Add a click event on app div (all the DOM) for know if user click outsite of the nav for close menu.
      document.querySelector('#app').addEventListener('click', function (el) { self.click(el) });
    },
    methods: {
      toggle_menu: function () {
        this.user_menu = false;
        this.link_menu = !this.link_menu;
      },
      toggle_user_menu: function () {
        this.link_menu = false;
        this.user_menu = !this.user_menu;
      },
      reloadNotification(){
        this.notifications = NotificationService.getNotification();
      },
      click(event = false){
        const clickedElement = event.target;
        const navbarElement = this.$refs.navbar;
        // If clickedElement is inside the navbarElement
        if (!this.isElementInsideOtherElement(clickedElement, navbarElement)) {
          // CLose menus
          this.link_menu = false;
          this.user_menu = false;
        }
      },
      isElementInsideOtherElement(elSearched, elRoot){
        if(!elRoot){ return false }
        return Array.from(elRoot.childNodes).filter((el) => {
          if(el.isEqualNode(elSearched)){ return true }
          return el.hasChildNodes() ? this.isElementInsideOtherElement(elSearched, el) : false ;
        }).length > 0;
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @require 'style.styl';
</style>
