<template>
  <h1><span>Dashboard / </span>Contrats</h1>
  <InsuranceBusinessTable/>
</template>

<script>

    import InsuranceBusinessTable from "@/components/tables/InsuranceBusinessTable.vue";

    export default {
        name: 'InsuranceBusinessListView',
        components: {
          InsuranceBusinessTable
        },
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  h1 span
      color: THEME_COLOR_PRIMARY_FADDED
</style>
