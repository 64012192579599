<template>
  <div style="height: 100%; display: flex">
    <div style="max-width: 400px; margin: auto;">
        <h1 style="padding-left: 20px; margin: auto;  padding-top: 25px; padding-bottom: 25px">Paramètres utilisateur</h1>
        <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
          <CustomTextField :content=inputs.email />
          <CustomTextField :content=inputs.name />
          <CustomTextField :content=inputs.isVerify />
          <CustomTextField :content=inputs.role />
          <CustomTextField :content=inputs.group />
          <CustomSwitchField :content=inputs.state />
          <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
        </CustomForm>
    </div>
  </div>
</template>

<script>
    import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
    import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
    import router from "@/router";
    import CustomSwitchField from "@/components/forms/customs/customSwitchField/CustomSwitchField.vue";
    import {ACTIVATION_STATUS_ENUM} from "@/models/activationStatusEnum";
    import {UserController} from "@/controllers/user/UserController";
    import {NotificationService} from "@/services/NotificationService";
    import CustomForm from "@/components/forms/CustomForm.vue";

    export default {
        name: 'UserParametersView',
        components: {
          CustomForm,
          CustomSwitchField,
            CustomButton,
            CustomTextField,
        },
        data: function () {
            return {
                inputs: {
                    email: {label: 'Email', value: '', readOnly: false, type: 'email'},
                    isVerify: {label: 'Compte vérifié', value: '', readOnly: true, type: 'text'},
                    name: {label: 'Nom', value: '', readOnly: false, type: 'text'},
                    role: {label: 'Rôle', value: '', readOnly: true, type: 'text'},
                    group: {label: 'Groupe', value: '', readOnly: true, type: 'text'},
                    state: { value: undefined, left: ACTIVATION_STATUS_ENUM.DEACTIVATED,
                      right: ACTIVATION_STATUS_ENUM.ACTIVATED, label: "Etat" , readOnly: true},
                },
                buttons:{
                  submit: {text: "Mettre à jour"},
                },
                error: false,
                message: ""
            }
        },
        async mounted(){
            this.$refs.form.startLoading();
            const resp = await UserController.get(localStorage.userId)
            if(!resp.errors){
              this.inputs.email.value = resp.email;
              this.inputs.name.value = resp.name;
              this.inputs.role.value = resp.role;
              this.inputs.group.value = this.getLabelOfGroupName(resp.groupName);
              this.inputs.state.value = resp.state;
              this.inputs.isVerify.value = resp.state;
            }
          this.$refs.form?.stopLoading();
        },
      methods: {
        getLabelOfGroupName(name){
          if(name === "super_admin"){ return "Super Administrateurs" }
          else if(name === "newer"){ return "Nouveaux" }
          else if(name === "unknown"){ return "Inconnus" }
          else{ return name}
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => UserController.patch(localStorage.userId, {
            email: this.inputs.email.value,
            name: this.inputs.name.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success","Utilisateur modifié");
            router.push("/user-parameters");
          }
        }
      }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
</style>