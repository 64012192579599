<template>
  <h1><span>Dashboard / </span>Recouvrement</h1>
  <CustomCarousel ref="carousel">
    <template v-slot:slide-0><MonthChoice :callback="selectMonth" /></template>
    <template v-slot:slide-1><InsuranceRecovery :month="selectedMonth" /></template>
  </CustomCarousel>
</template>

<script>
import MonthChoice from "@/components/MonthChoice.vue";
import InsuranceRecovery from "@/components/InsuranceRecovery.vue";
import CustomCarousel from "@/components/CustomCarousel.vue";

export default {
  name: 'InsuranceBusinessRecoveryView',
  components: {
    CustomCarousel,
    MonthChoice,
    InsuranceRecovery,
  },
  data() {
    return {
      selectedMonth: undefined,
      currentSlide: 0
    };
  },
  methods: {
    selectMonth(monthNumber) {
      this.selectedMonth = monthNumber;
      this.goToSlide(1);
    },
    goToSlide(index) {
      this.$refs.carousel.goToSlide(index);
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/src/common/style/common.styl';

h1 span
  color: THEME_COLOR_PRIMARY_FADDED
</style>

