export const CLIENT_STATUS_ENUM = {
    CONTACT: { label: 'Prise de contact', value: "CONTACT" },           // Prise de contact
    QUALIFIED: { label: 'Qualifié', value: "QUALIFIED" },               // Qualifié
    INTERESTED: { label: 'Intéressé', value: "INTERESTED" },             // Intéressé
    CONVERTED: { label: 'Converti', value: "CONVERTED" },               // Converti
    UNINTERESTED: { label: 'Non Intéressé', value: "UNINTERESTED" },    // Non Intéressé
}

export const CLIENT_STATUS_ENUM_ARRAY= [
    CLIENT_STATUS_ENUM.CONTACT,
    CLIENT_STATUS_ENUM.QUALIFIED,
    CLIENT_STATUS_ENUM.INTERESTED,
    CLIENT_STATUS_ENUM.CONVERTED,
    CLIENT_STATUS_ENUM.UNINTERESTED
]