<template>
    <div>
      <h2>Politique de cookies</h2>
      <p>
        Ce site utilise des cookies pour améliorer votre expérience de navigation, analyser l'audience du site et
        permettre son bon fonctionnement. En poursuivant votre navigation sur ce site, vous acceptez l'utilisation
        de cookies conformément à notre Politique de confidentialité.
        Veuillez sélectionner les types de cookies que vous acceptez :
      </p>
      <ul>
        <li>
          <CustomSwitchField class="switch" :content=inputs.analyticsCookie />
          <p>
            <strong>Cookies d'analyse</strong> : Ces cookies nous aident à comprendre comment les visiteurs interagissent avec le site en
            recueillant et rapportant des informations de manière anonyme.
          </p>
        </li>
        <li>
          <CustomSwitchField class="switch" :content=inputs.workCookie />
          <p>
            <strong>Cookies nécessaires au fonctionnement du site</strong> : Ces cookies sont essentiels au bon fonctionnement du site
            et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement établis en réponse à des actions
            que vous effectuez et qui constituent une demande de services, telles que la définition de vos préférences
            en matière de confidentialité, la connexion ou le remplissage de formulaires.
          </p>
        </li>
      </ul>
      <p>
        En cochant les cases ci-dessus, vous consentez à l'utilisation des cookies sélectionnés conformément à notre
        Politique de confidentialité. Vous pouvez modifier vos paramètres de cookies à tout moment en consultant
        vos paramètres utilisateur.
      </p>
    </div>
</template>

<script>
  import CustomSwitchField from "@/components/forms/customs/customSwitchField/CustomSwitchField.vue";

  export default {
    name: 'CookiesContent',
    components: {CustomSwitchField},
    props: ['cookies'],
    data: function () {
      return {
        inputs: {
          analyticsCookie: {
            value: true,
            left: { label: "", value: false},
            right: { label: "", value: true},
            lightMode: true
          },
          workCookie: {
            value: true,
            left: { label: "", value: false},
            right: { label: "", value: true},
            lightMode: true
          },
        }
      }
    },
    watch: {
      "inputs.workCookie.value"(val) {
        this.$emit('workCookieUpdated', !val)
      }
    }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl';
  ul
    padding-inline-start 10px
    li
      display flex
      text-decoration none
      .switch
        width 70px
        padding-top 15px
        padding-right 10px
      p
        width 100%
</style>
