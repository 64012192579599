<template>
  <div class="global-container">
    <h1>Assistance</h1>
    <h2>Assistance technique</h2>
    <p>Rencontrez-vous des problèmes techniques ? Une erreur incompréhensible, un bug gênant ou un problème bloquant ? Ne restez pas seul face à ces difficultés, contactez-moi.</p>
    <h2>Questions sur les modules ?</h2>
    <p>Vous souhaitez en savoir plus sur l'un de nos modules ou même le tester ? N'hésitez pas à me contacter pour obtenir toutes les informations nécessaires.</p>
    <h2>Formation personnalisée</h2>
    <p>Bénéficiez d'une formation gratuite si besoin ! Destinée aux utilisateurs potentiels de l'application, cette formation vous permettra de maîtriser son utilisation en toute simplicité.</p>
    <h2>Développement de modules sur mesure</h2>
    <p>Vous avez des besoins spécifiques et souhaitez un module personnalisé ? Contactez-moi pour discuter de vos besoins et voir comment je peux vous aider.</p>
    <h2>Comment me contacter ?</h2>
    <p>Vous pouvez me contacter sur <a href="https://www.linkedin.com/in/franck-besson-fr/">linkedin</a>, par <a class="hyperlink" href="mailto:contact@citrondigital.fr">mail (contact@citrondigital.fr)</a> ou par <a class="hyperlink" href="tel:0614851819">téléphone (+33 6 14 85 18 19)</a>.</p>
  </div>
</template>

<script>
    export default {
        name: 'AssistanceView',
    }
</script>

<style lang="stylus" scoped>
@require '~/src/common/style/common.styl';
.global-container
  padding-left 50px
  padding-right 50px
h1
  text-align center
a
  text-decoration none
  color THEME_COLOR_PRIMARY
</style>