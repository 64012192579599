<template>
  <div class="container" ref="select">
    <div @click="toggleSelect" class="wrapper-dropdown" :class="[{'required': selfContent.required, 'active': isSelectExpanded, 'bad_input': selfContent.error, 'read-only': selfContent.readOnly}]">
      <div class="span-container" :class="{'original-label': initialLabel === selfContent.label}"><span>{{selfContent.label}}</span></div>
      <ul v-if="withoutSelectedValue(selfContent.selectValues).length > 0" class="dropdown">
        <li v-for="(value, index) in withoutSelectedValue(selfContent.selectValues)" :key="index" @click="selectThis(value.value)">{{ value.label }}</li>
      </ul>
      <ul v-else class="dropdown">
        <li><br /></li>
      </ul>
    </div>
  </div>
</template>

<script>
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faLock } from "@fortawesome/free-solid-svg-icons";

 library.add(faLock);

  export default {
      name: 'CustomSelectField',
      props: ['content'],
      data: function () {
          return {
              selfContent: this.content,
              isSelectExpanded: false,
              initialLabel: undefined
          };
      },
      watch: {
        "selfContent.value"(e) {
          this.selectThis(e);
        },
      },
      mounted(){
        this.initialLabel = this.selfContent.label;
        this.selectThis(this.selfContent.value);
      },
      methods: {
        toggleSelect(){
          if(!this.selfContent.readOnly){
            this.isSelectExpanded = !this.isSelectExpanded;
            if(this.isSelectExpanded){
              const mouseUpHandler =  (event) => {
                if (!this.isClickOnSelect(event)) {
                  this.isSelectExpanded = false;
                  document.removeEventListener("mouseup", mouseUpHandler);
                }
              };
              document.addEventListener("mouseup", mouseUpHandler);
            }
          }
        },
        isClickOnSelect(event){
          const clickedElement = event.target;
          const selectDiv = this.$refs.select;
          return selectDiv && selectDiv.contains(clickedElement);
        },
        selectThis(selectedOption){
          if(selectedOption) {
            this.selfContent.value = selectedOption;
            const value = this.selfContent.selectValues.filter(o => {
              return o.value === selectedOption
            })[0];
            this.selfContent.label = value ? value.label : this.selfContent.label;
            this.selfContent.error = false;
          }else{
            this.selfContent.label = this.initialLabel;
          }
        },
        withoutSelectedValue(selectValues){
          const self = this;
          return selectValues.filter(function(item) {
            return item.value !== self.selfContent.value;
          });
        }
      }
  }

</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  //@media only screen and (min-width: DESKTOP_SIZE)
  //@media only screen and (min-width: TABLET_SIZE)

  .container
    position relative
    width 100%

  .wrapper-dropdown
    position relative

    display inline-block
    width 90%
    height 55px
    padding 0 3% 0 7%

    border-radius 3px
    background rgba(255, 255, 255, 0.95)
    box-shadow 0 2px 2px 0 rgba(0 ,0 , 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2)

    text-align left
    line-height 40px
    color white
    background-color THEME_COLOR_PRIMARY

    cursor pointer

    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none

  .span-container
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    width 90%
    height 100%
    padding-right 10%
    display flex
    align-items center
    span
      padding-left: 5px
      overflow hidden
      text-overflow ellipsis
      white-space nowrap

  .wrapper-dropdown::before
    content ''
    position absolute
    top 50%
    right 16px

    margin-top -2px
    width 0
    height 0

    border-width 6px 6px 0 6px
    border-style solid
    border-color white transparent

  .wrapper-dropdown.active::before
    border-width 0 6px 6px 6px

  .wrapper-dropdown .dropdown
    position absolute
    z-index 1
    top 140%
    right 0
    left 0

    margin 0
    padding 0

    list-style none

    border-radius inherit
    box-shadow 0 1px 15px 10px rgba(255, 255, 255, 0.3)
    background inherit

    -webkit-transition .5s ease
    -moz-transition .5s ease
    -ms-transition .5s ease
    transition .5s ease

    -webkit-transform scaleY(0)
    -moz-transform scaleY(0)
    -ms-transform scaleY(0)
    transform scaleY(0)

    -webkit-transform-origin top
    -moz-transform-origin top
    -ms-transform-origin top
    transform-origin top

    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none

    opacity 0
    visibility hidden

  .wrapper-dropdown .dropdown li
    padding 0 15px
    overflow hidden

    -webkit-transition .5s ease
    -moz-transition .5s ease
    -ms-transition .5s ease
    transition .5s ease

  .wrapper-dropdown .dropdown li:last-child
    border-bottom none

  .wrapper-dropdown .dropdown li:hover
    background THEME_COLOR_PRIMARY_FADDED

  .wrapper-dropdown.active .dropdown
    opacity 1
    visibility visible

    -webkit-transform scaleY(1)
    -moz-transform scaleY(1)
    -ms-transform scaleY(1)
    transform scaleY(1)

  .bad_input
    box-shadow 0 2px 1px #cd384e

  .wrapper-dropdown.read-only
    background-color #d7d7d7
    cursor auto

  li
    overflow hidden
    text-overflow ellipsis
    white-space nowrap


  .required .original-label span::after
    content " *"
    color white
    &.bad_input
      color #cd384e
</style>
