import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class InsuranceBusinessController extends Controller {

     static getAll(urlParams) {
         return this.requestBuilder("GET",`/insurance/insurance-business/table/all${urlParams}`, true);
     }

     static getByMonth(month) {
         return this.requestBuilder("GET",`/insurance/insurance-business/table/month?number=${month}`, true);
     }

     static post(body) {
         return this.requestBuilder("POST",`/insurance/insurance-business/insurance`, true, body, {});
     }

     static extractCompanyRecovery(body) {
         return this.requestBuilder("POST",`/insurance/insurance-business/extract-company-recovery`, true, body, {});
     }

     static manualPost(body) {
         return this.requestBuilder("POST",`/insurance/insurance-business/insurance`, true, body);
     }

     static patch(businessId, body) {
         return this.requestBuilder("PATCH",`/insurance/insurance-business/${businessId}`, true, body);
     }

}
