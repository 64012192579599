<template>
  <div class="global-container">
    <div class="desktop-only">
      <div class="design">
        <div class="text">
          <h1 style="font-size: 3.5em !important">Connectez-vous</h1>
          <h1 style="font-size: 2.7em !important">Et digitalisez votre entreprise</h1>
        </div>
      </div>
    </div>
    <NewPasswordForm v-if="this.$route.params.token"/>
    <ResetPasswordForm v-else/>
  </div>
</template>

<script>
    import ResetPasswordForm from "../components/forms/ResetPasswordForm.vue";
    import NewPasswordForm from "@/components/forms/NewPasswordForm.vue";

    export default {
        name: 'ResetPasswordView',
        components: {
            ResetPasswordForm,
            NewPasswordForm,
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  @require 'dualViews.styl';
</style>