import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class InsuranceCollectionController extends Controller {

     static getAll(businessId, urlParams) {
         return this.requestBuilder("GET",`/insurance/insurance-collections/by-business/${businessId}?${urlParams}`, true);
     }

     static post(body) {
         return this.requestBuilder("POST",`/insurance/insurance-collections`, true, body);
     }

}
