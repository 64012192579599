<template>
    <div class="container-screen">
        <div class="screen">
          <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
              <CustomTextField :content=inputs.name />
              <CustomTextField :content=inputs.email style="padding-top: 20px;"/>
              <CustomTextField :content=inputs.password style="padding-top: 20px;"/>
              <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
              <div class="separator">
                <span></span>
                <label>Se connecter ?</label>
                <span></span>
              </div>
              <router-link :to="'/login'"  style="width: 100%">
                  <CustomButton :params=buttons.back />
              </router-link>
          </CustomForm>
        </div>
    </div>
</template>

<script>
 import CustomButton from "./customs/customButton/CustomButton.vue";
 import CustomTextField from "./customs/customTextField/CustomTextField.vue";
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faUser, faLock, faChevronRight, faAt } from "@fortawesome/free-solid-svg-icons";
 import router from '../../router';
 import {UserController} from "@/controllers/user/UserController";
 import CustomForm from "@/components/forms/CustomForm.vue";

 library.add(faUser);
 library.add(faAt);
 library.add(faChevronRight);
 library.add(faLock);

  export default {
      name: 'RegisterForm',
      components: {
        CustomForm,
          CustomButton,
          CustomTextField,
      },
      data() {
          return {
              inputs: {
                  name: {label: 'Prénom', value: '', error: false, type: 'text'},
                  email: {label: 'Email', value: '', error: false, type: 'email'},
                  password: {label: 'Mot de passe', value: '', error: false, type: 'password'},
              },
              buttons: {
                submit: {text: "M'inscrire"},
                back: {text: "Retour"},
              },
              error: false,
              message: ""
          };
      },
      methods: {
          async submitForm() {
            const res = await this.$refs.form.submitForm(() => UserController.register({
              email: this.inputs.email.value,
              password: this.inputs.password.value,
              name: this.inputs.name.value
            }));
            if (!res.errors) {
              router.push("/confirm-mail");
            }else{
              for(let i=0; i < res.errors.length ;i++) {
                if(res.errors[i].field === "NAME"){this.inputs.name.error = true}
                if(res.errors[i].field === "EMAIL"){this.inputs.email.error = true}
              }
            }
          }
      }
  }

</script>

<style lang="stylus" scoped>
    @import '~/src/common/style/common.styl'
    @import 'responsive-form.styl'
</style>
