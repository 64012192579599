<template>
  <header>
    <Head></Head>
    <ConfiguratedNav/>
  </header>
  <main>
    <div>
      <router-view/>
    </div>
  </main>
  <CustomFooter logo_img="../../img/logo_nav.png" />
</template>

<script>

  import ConfiguratedNav from "@/components/configuratedNav/ConfiguratedNav.vue";
  import CustomFooter from "@/components/customFooter/CustomFooter.vue";
  import {Head} from "@vueuse/head";

  export default {
    components: {
      // eslint-disable-next-line vue/no-reserved-component-names
      Head,
      CustomFooter,
      ConfiguratedNav
    },
    data: function () {
      return {
        isPhone: false,
        isTablet: false,
        isDesktop: false,
      }
    },
    methods: {
      setResolutionType() {
        const MAX_RES_PHONE = 700;
        const MAX_RES_TABLET = 1200;
        this.isPhone = window.outerWidth < MAX_RES_PHONE;
        this.isTablet = MAX_RES_PHONE <= window.outerWidth && window.outerWidth < MAX_RES_TABLET;
        this.isDesktop = MAX_RES_TABLET <= window.outerWidth;
      }

    },
    mounted() {
      this.setResolutionType();
      window.onresize = function () {
        this.setResolutionType();
      }.bind(this);
    }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl';
</style>