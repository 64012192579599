<template>
  <div style="height: 100%; display: flex; align-items: center; justify-content: center;">
    <h1>Bienvenue</h1>
  </div>
</template>

<script>
    export default {
        name: 'HomeView',
    }
</script>