<template>
    <div class="container-screen">
        <div class="screen">
            <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
                <CustomTextField :content=inputs.email />

                <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />

                <div class="separator">
                  <span></span>
                  <label>Se connecter ?</label>
                  <span></span>
                </div>

                <router-link :to="'/login'" style="width: 100%">
                    <CustomButton :params=buttons.back />
                </router-link>
            </CustomForm>
        </div>
    </div>
</template>

<script>
 import CustomButton from "./customs/customButton/CustomButton.vue";
 import CustomTextField from "./customs/customTextField/CustomTextField.vue";
 import { library } from '@fortawesome/fontawesome-svg-core'
 import { faAt } from "@fortawesome/free-solid-svg-icons";
 import {UserController} from "@/controllers/user/UserController";
 import CustomForm from "@/components/forms/CustomForm.vue";
 library.add(faAt);

  export default {
      name: 'ConfirmMail',
      components: {
        CustomForm,
          CustomButton,
          CustomTextField,
      },
      data() {
          return {
              inputs: {
                  email: {label: 'Email', value: '', type: 'email'},
              },
              buttons:{
                submit: {text: "Envoyer"},
                back: {text: "Retour"},
              },
          };
      },
      methods: {
        startResetPasswordTimer() {
          this.timer = 15;
          this.buttons.submit.disabled = true;
          this.buttons.submit.text = `Renvoyer (${this.timer}s)`;
          const interval = setInterval(() => {
            if(this.timer > 0) {
              this.timer--;
              this.buttons.submit.text = `Renvoyer (${this.timer}s)`;
            }
          },1000);
          setTimeout(() => {
            clearInterval(interval);
            this.buttons.submit.text = `Renvoyer`;
            this.buttons.submit.disabled = false;
          }, 15000);
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => UserController.askResetPassword(
            this.inputs.email.value
          ));
          if (!res.errors) {
            this.startResetPasswordTimer();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl'
  @import 'responsive-form.styl'
</style>