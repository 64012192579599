import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from "../views/RegisterView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import LogoutView from "../views/LogoutView.vue";
import UserParametersView from "../views/UserParametersView.vue";
import ConfirmMailView from "../views/ConfirmMailView.vue";
import ClientListView from "@/views/ClientListView.vue";
import MailAlreadyCheckView from "@/views/MailAlreadyCheckView.vue";
import UserListView from "@/views/UserListView.vue";
import LoginView from "@/views/LoginView.vue";
import LegalView from "@/views/LegalView.vue";
import WhoView from "@/views/WhoView.vue";
import AssistanceView from "@/views/AssistanceView.vue";
import ChooseGroupView from "@/views/ChooseGroupView.vue";
import InsuranceBusinessListView from "@/views/InsuranceBusinessListView.vue";
import InsuranceClientListView from "@/views/InsuranceClientListView.vue";
import BusinessListView from "@/views/BusinessListView.vue";
import GroupParametersView from "@/views/GroupParametersView.vue";
import AcceptCGUView from "@/views/AcceptCGUView.vue";
import InsuranceBusinessRecoveryView from "@/views/InsuranceBusinessRecoveryView.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/legal',
    name: 'Legal',
    component: LegalView
  },
  {
    path: '/who',
    name: 'Who',
    component: WhoView
  },
  {
    path: '/assistance',
    name: 'Assistance',
    component: AssistanceView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView
  },
  {
    path: '/confirm-mail/:token?',
    name: 'ConfirmMail',
    component: ConfirmMailView
  },
  {
    path: '/accept-cgu',
    name: 'AcceptCGU',
    component: AcceptCGUView
  },
  {
    path: '/choose-group',
    name: 'ChooseGroup',
    component: ChooseGroupView
  },
  {
    path: '/mail-already-check',
    name: 'MailAlreadyCheck',
    component: MailAlreadyCheckView
  },
  {
    path: '/reset-password/:token?',
    name: 'ResetPassword',
    component: ResetPasswordView
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutView
  },
  {
    path: '/businesses',
    name: 'BusinessList',
    component: BusinessListView
  },
  {
    path: '/clients',
    name: 'ClientList',
    component: ClientListView
  },
  {
    path: '/insurance-businesses',
    name: 'InsuranceBusinessList',
    component: InsuranceBusinessListView
  },
  {
    path: '/insurance-recovery',
    name: 'InsuranceBusinessRecovery',
    component: InsuranceBusinessRecoveryView
  },
  {
    path: '/insurance-clients',
    name: 'InsuranceClientList',
    component: InsuranceClientListView
  },
  {
    path: '/users',
    name: 'UserList',
    component: UserListView
  },
  {
    path: '/user-parameters',
    name: 'UserParameters',
    component: UserParametersView
  },
  {
    path: '/group-parameters',
    name: 'GroupParameters',
    component: GroupParametersView
  },
  {
    path: "/:catchAll(.*)",
    component: HomeView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router
