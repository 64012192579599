import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class InsuranceClientController extends Controller {

     static getAll(urlParams) {
         return this.requestBuilder("GET",`/insurance/insurance-clients${urlParams}`, true);
     }

}
