<template>
  <CustomTable ref="table" :callback=reloadCallback :filters=filtersInputs>
    <template v-slot:header-options>
      <button @click="$refs.addClientModal.openModal()" class="button-icon"><span class="fas fa-plus"></span></button>
    </template>
    <template v-slot:table>
      <thead>
        <tr>
          <th>Date de création</th>
          <th>Nom</th>
          <th>Description</th>
          <th>Address</th>
          <th>Contact</th>
          <th>Dernier contact</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="data && data.length > 0">
        <tr v-for="(client, index) in $refs.table.$refs.searchArray.simpleFilter(data)" :key="index" :class="client.status">
          <td>{{(new Date(client.createdAt)).toLocaleDateString("en-GB", formattedDate)}}</td>
          <td>{{client.name}}</td>
          <td>{{client.description}}</td>
          <td>{{client.address}}</td>
          <td>{{client.contact}}</td>
          <td>{{client.lastContact ? timeAgo(client.lastContact) : '-'}}</td>
          <td>{{client.status ? CLIENT_STATUS_ENUM[client.status].label : '' }}</td>
          <td>
            <button class="button-icon-custom" @click="$refs.editClientModal.openModal(client)"><span class="fas fa-pen"></span></button>
          </td>
        </tr>
      </tbody>
      <tbody v-else><tr style="background-color: #40513b14;font-weight: bold"><td colspan="12" style="text-align: center">Aucun contrat enregitré</td></tr></tbody>
    </template>
  </CustomTable>
  <AddClientModal ref="addClientModal" :callback=loadData />
  <EditClientModal ref="editClientModal" :callback=loadData />
</template>

<script>
    import {ClientController} from "@/controllers/business/ClientController";
    import AddClientModal from "@/components/modals/AddClientModal.vue";
    import EditClientModal from "@/components/modals/EditClientModal.vue";
    import CustomTable from "@/components/CustomTable.vue";
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faMoneyBillTransfer, faList, faChevronLeft, faChevronRight, faPen, faTrashCan, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
    import {CLIENT_STATUS_ENUM} from "@/models/clientStatusEnum";
    import {timeAgo} from "../../utils";
    library.add(faChevronLeft, faPen, faList, faMoneyBillTransfer, faChevronRight, faTrashCan, faEye, faEyeSlash);

    export default {
        name: 'ClientTable',
      computed: {
        CLIENT_STATUS_ENUM() {
          return CLIENT_STATUS_ENUM
        }
      },
        components: {
          CustomTable,
          EditClientModal,
          AddClientModal
        },
        data: function () {
          return {
            data: [],
            formattedDate: {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit"
            },
            filtersInputs: {
              field: {label: 'Champs', value: '', type: 'select', selectValues: [
                  { label: "Date de création", value: "createdAt" },
                  { label: "Souscripteur", value: "subscriber" },
                ]},
              sort: {label: 'Trié par', value: '', type: 'select', selectValues: [
                  { label: "Date de création", value: "createdAt" },
                  { label: "Souscripteur", value: "subscriber" },
                ]},
            }
          }
        },
        mounted(){
          this.loadData();
        },
        methods: {
          timeAgo,
          reloadCallback(){
            window.location.href=`/clients${this.$refs.table.buildURLParams()}`;
          },
          async loadData(){
            this.$refs.table.startLoading();
            this.data = [];
            const resp = await ClientController.getAll(this.$refs.table.buildURLParams());
            if(!resp.errors){
              for(let i=0; i < resp.length ;i++){
                // Decrypt needed for some fields
                let client = {};
                client.name = resp[i].name;
                client.description = resp[i].description;
                client.address = resp[i].address;
                client.contact = resp[i].contact;
                client.lastContact = resp[i].lastContact;
                this.data.push(Object.assign(resp[i], client));
              }
            }
            this.$refs.table?.stopLoading();
          }
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  .button-icon
    margin-right 10px

  .CONTACT
    background-color #FFD10036 !important
  .QUALIFIED
    background-color #d9800036 !important
  .INTERESTED
    background-color #ff4f0036 !important

</style>