<template>
    <div class="container-screen">
        <div class="screen">
            <div class="header">
                <h1>Activation du compte</h1>
            </div>

            <p style="margin-left: 20px;margin-right: 20px;">Veillez patienter le temps que votre compte s'active. Si ce message reste plus de 15 secondes vous pouvez relancer l'activation via ce bouton :</p>

            <CustomForm ref="form" :inputs=[] :submit=buttons.submit>
              <CustomButton @click=submitForm :params="buttons.submit" style="margin-top: 30px" />
            </CustomForm>
        </div>
    </div>
</template>

<script>
 import CustomButton from "./customs/customButton/CustomButton.vue";
 import {NotificationService} from "@/services/NotificationService";
 import router from "@/router";
 import {UserController} from "@/controllers/user/UserController";
 import CustomForm from "@/components/forms/CustomForm.vue";

  export default {
      name: 'SendConfirmMail',
      components: {
        CustomForm,
          CustomButton
      },
      data() {
          return {
              buttons:{
                submit: {text: "Renvoyer"},
              },
              timer: 0
          };
      },
    mounted() {
      this.submitForm();
    },
    methods: {
        startSubmitTimer() {
          this.timer = 15;
          this.buttons.submit.disabled = true;
          this.buttons.submit.text = `Renvoyer (${this.timer}s)`;
          const interval = setInterval(() => {
            if(this.timer > 0) {
              this.timer--;
              this.buttons.submit.text = `Renvoyer (${this.timer}s)`;
            }
          },1000);
          setTimeout(() => {
            clearInterval(interval);
            this.buttons.submit.text = `Renvoyer`;
            this.buttons.submit.disabled = false;
          }, 15000);
        },
        async submitForm(){
          const res = await this.$refs.form.submitForm(() => UserController.confirmMail(
              this.$route.params.token
          ));
          if (!res.errors) {
            (new NotificationService).addNotification("success","L'adresse mail est vérifiée, vous pouvez vous connecter");
            router.push("/login");
          }else{
            this.startSubmitTimer();
            for(let i=0; i < res.errors.length ;i++){
              if(res.errors[i].code === "MAIL_ALREADY_CHECK") {
                router.push("/mail-already-check");
              }
            }
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
  @import '~/src/common/style/common.styl'
  @import 'responsive-form.styl'
</style>
