<template>
  <footer>
      <!-- <img class="logo" v-bind:src=imgPath alt="Logo"/> -->
    <div class="footer-container">
      <div class="sections">
        <div class="section">
          <router-link to="/legal" class="router-link"><h3>Legal</h3></router-link>
          <router-link to="/legal" class="router-link"><i class="icon fas fa-gavel"></i></router-link>
        </div>
        <div class="section">
          <router-link to="/who" class="router-link"><h3>Informations</h3></router-link>
          <router-link to="/who" class="router-link"><i class="icon fas fa-building-user"></i></router-link>
        </div>
        <div class="section">
          <router-link to="/assistance" class="router-link"><h3>Assistance</h3></router-link>
          <router-link to="/assistance" class="router-link"><i class="icon fas fa-circle-question"></i></router-link>
        </div>
      </div>
      <div class="copyright">
          <span><i class="icon fas fa-copyright"></i> {{new Date().getFullYear()}} Copyright</span>
          <span>Made with pep<span class="peps">s</span> by <a class="hyperlink" href="https://www.citron-digital.fr" alt="Franck Web, webdesign services">Citron Digital</a></span>
      </div>
    </div>
  </footer>
</template>

<script>
  import { library } from '@fortawesome/fontawesome-svg-core'
  import {faBuildingUser, faCircleQuestion, faCopyright, faGavel} from "@fortawesome/free-solid-svg-icons";
  library.add(faCopyright);
  library.add(faCircleQuestion);
  library.add(faGavel);
  library.add(faBuildingUser);

  export default {
    name: 'CustomFooter',
    props: ['logo_img', 'link_routes', 'user_routes'],
    data: function() {
      return {
        imgPath: this.logo_img
      };
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';

  @media only screen and (min-width: DESKTOP_SIZE)
    .section
      .icon
        position absolute

    footer
      height 100px !important

  //@media only screen and (min-width: TABLET_SIZE)

  .copyright > a
    color green !important
    text-decoration none !important

  footer
    width 100%
    height 135px
    margin-top 1rem
    color white
    background-color rgba(THEME_COLOR_NAV_HEADER,1)
    font-size: 0.8em;

  .logo
    text-align center
    display flex
    margin auto
    height 40px

  .copyright
    padding-top 10px
    padding-left 20px
    padding-right 20px
    //text-align center
    display: flex;
    align-items: center;
    justify-content: space-between;

  .hyperlink
    text-decoration none
    color THEME_COLOR_PRIMARY_OPPOSITE
    font-weight bold

  .footer-container
    display flex
    width 100%
    height 100%
    flex-direction column

  .sections
    padding-top 20px
    padding-bottom 20px
    padding-right 15%
    padding-left 15%
    display flex
    justify-content space-evenly

    .section
      //height 20px
      width 33%
      text-align center

      .icon
        margin-top 10px
        height 25px
        width 25px
        margin-left -12px

  h3
    margin 0

  .peps
    color THEME_COLOR_TERTIARY

</style>
