<template>
  <h1><span>Dashboard / </span>Clients</h1>
  <ClientTable/>
</template>

<script>
    import ClientTable from "@/components/tables/ClientTable.vue";

    export default {
        name: 'ClientListView',
        components: {
          ClientTable
        }
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  h1 span
      color: THEME_COLOR_PRIMARY_FADDED
</style>