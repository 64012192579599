import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class UserController extends Controller {
     static async getAll(filterParams) {
         return this.requestBuilder("GET",`/user/users${filterParams}`, true);
     }

     static async banUsers(userId) {
         return this.requestBuilder("PATCH",`/user/users/ban/${userId}`, true);
     }

     static async addUserToGroup(token) {
         return this.requestBuilder("PATCH",`/user/users/set-group/${token}`, true);
     }

     static async patch(userId, body) {
         return this.requestBuilder("PATCH",`/user/users/${userId}`, true, body);
     }

     static async get(userId) {
         return this.requestBuilder("GET",`/user/users/${userId}`, true);
     }

     static async resetPassword(token, password) {
         return this.requestBuilder("POST",`/auth/reset-password/${token}`, true, {password: password});
     }

     static async register(body) {
         return this.requestBuilder("POST",`/auth/register`, true, body);
     }

     static async login(email, password) {
         return this.requestBuilder("POST",`/auth/login`, true, {email: email, password: password});
     }

     static async askConfirmMail(email) {
         return this.requestBuilder("GET",`/auth/send-account-confirm-mail/${email}`, true);
      }

     static async askResetPassword(email) {
         return this.requestBuilder("GET",`/auth/reset-password/${email}`, true);
     }

     static async confirmMail(token) {
         return this.requestBuilder("GET",`/auth/confirm-mail/${token}`, true);
     }

}
