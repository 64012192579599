<template>
  <CustomModal ref="modal">
    <h2>Encaissements</h2>
    <div class="container">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <CustomTextField :content=inputs.amount />
        <CustomSelectField class="select" :content=inputs.paymentType />
        <CustomButton class="button" @click="submitForm" :params=buttons.submit />
      </CustomForm>
      <CollectionTable ref="collectionTable"/>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
import {NotificationService} from "@/services/NotificationService";
import { PAYMENT_TYPE } from "@/models/paymentType";
import {CollectionController} from "@/controllers/business/CollectionController";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import CollectionTable from "@/components/tables/CollectionTable.vue";
library.add(faXmark);

  export default {
      name: 'AddCollectionModal',
      props: ['callback'],
      components: {CollectionTable, CustomModal, CustomForm, CustomSelectField, CustomTextField, CustomButton},
      data() {
          return {
            businessId: undefined,
            inputs: {
              amount: {label: 'Montant', value: 0, type: 'number'},
              paymentType: {label: 'Type de paiement', value: PAYMENT_TYPE.TRANSFER.value, type: 'select', selectValues: [PAYMENT_TYPE.CHECK, PAYMENT_TYPE.CASH, PAYMENT_TYPE.TRANSFER]},
            },
            buttons:{
              submit: {text: "Encaisser"},
            }
          };
      },
      methods: {
        openModal(businessId) {
          this.$refs.modal.openModal();
          this.businessId = businessId;
          this.inputs.amount.value = undefined;
          this.inputs.paymentType.value = PAYMENT_TYPE.TRANSFER.value;
          this.$refs.collectionTable.$refs.table.$refs.paginationArray.init(0,5);
          this.$refs.collectionTable.retrieveData(businessId)
        },
        closeModal(event = false) {
          this.$refs.modal?.closeModal(event);
        },
        async submitForm() {
          if(!this.inputs.amount.value){
            this.inputs.amount.error = true;
            (new NotificationService).addNotification("danger","Le montant n'est pas renseigné");
            return;
          }

          if(!this.inputs.paymentType.value){
            this.inputs.paymentType.error = true;
            (new NotificationService).addNotification("danger","Le type de paiement n'est pas renseigné");
            return;
          }

          const res = await this.$refs.form.submitForm(() => CollectionController.post({
            amount: this.inputs.amount.value,
            paymentType: this.inputs.paymentType.value,
            businessId: this.businessId
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success","Encaissement réalisé");
            this.closeModal(true);
            this.callback();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';

    h2
      margin-bottom 0
      text-align center

    .container
      padding-left 15px
      padding-right 15px

      form
        display flex
        flex-direction row
        .select, .button
          margin-top 18px
        .select
          padding-right 10px
</style>
