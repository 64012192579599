<template>
  <CustomTable ref="table" :callback=reloadCallback :defaultItemsPerPages=5 :defaultIindex=0 :disableSearch=true>
    <template v-slot:table>
      <thead>
      <tr>
        <th>Date</th>
        <th>Type de paiment</th>
        <th>Montant</th>
      </tr>
      </thead>
      <tbody v-if="data && data.length > 0 && data">
        <tr v-for="(collection, index) in data" :key="index">
          <td>{{new Date(collection.createdAt).toLocaleDateString("en-GB", formattedDate)}}</td>
          <td>{{this.convertPaymentType(collection.paymentType)}}</td>
          <td>{{collection.amount}}</td>
        </tr>
      </tbody>
      <tbody v-else><tr style="background-color: #40513b14;font-weight: bold; text-align: center"><td colspan="3">Aucun encaissement enregistré</td></tr></tbody>
    </template>
  </CustomTable>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CustomTable from "@/components/CustomTable.vue";
import {InsuranceCollectionController} from "@/controllers/insurance/InsuranceCollectionController";
library.add(faXmark);

  export default {
    name: 'InsuranceCollectionTable',
    components: {
      CustomTable
    },
    data() {
        return {
          data: [],
          formattedDate: {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
          },
          businessId: undefined,
        };
    },
    methods: {
        async retrieveData(businessId){
          // We keep the id for pagination or filter
          this.businessId = businessId;
          this.$refs.table.startLoading();
          this.data = [];
          const resp = await InsuranceCollectionController.getAll(businessId, this.$refs.table.$refs.paginationArray.urlParamsBuilder());
          if(!resp.errors){
            for(let i=0; i < resp.length ;i++){
              this.data.push(resp[i]);
            }
          }
          this.$refs.table?.stopLoading();
        },
        convertPaymentType(paymentType){
          if(paymentType === "CASH"){ return "Espèce" }
          else if(paymentType === "CHECK"){ return "Chèque" }
          else if(paymentType === "TRANSFER"){ return "Virement" }
          else{ return "-" }
        },
        reloadCallback(){
          this.retrieveData(this.businessId);
        },
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
</style>
