<template>
  <CustomModal ref="modal">
    <h2 style="text-align: center">Modifier un devis</h2>
    <div style="padding-left: 100px; padding-right: 100px">
      <CustomForm ref="form" :inputs=inputs :submit=buttons.submit>
        <div class="state-container">
          <CustomSelectField :content=inputs.type />
          <div class="separator-state-container"></div>
          <CustomSelectField ref="statusSelect" :content=inputs.status />
        </div>
        <div class="separator"></div>
        <CustomSelectField style="margin-top: 30px" :content=inputs.client />
        <CustomTextAreaField :content=inputs.addressPro />
        <CustomTextField :content=inputs.number />
        <div class="separator"></div>
        <CustomSelectField style="margin-top: 30px" :content=inputs.delay />
        <div class="tva-container">
          <div class="numeroTVA"><CustomTextField :content=inputs.tvaNumber /></div>
          <div class="TVA"><CustomTextField :content=inputs.tva /></div>
        </div>
        <CustomTextAreaField :content=inputs.bank />
        <CustomButton @click="submitForm" :params=buttons.submit style="margin-top: 30px" />
      </CustomForm>
    </div>
  </CustomModal>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faXmark, faPlus, faUpLong, faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "@/components/forms/customs/customButton/CustomButton.vue";
import CustomTextField from "@/components/forms/customs/customTextField/CustomTextField.vue";
import CustomSelectField from "@/components/forms/customs/customSelectField/CustomSelectField.vue";
import {ClientController} from "@/controllers/business/ClientController";
import {BUSINESS_STATUS_ENUM} from "@/models/businessStatusEnum";
import {NotificationService} from "@/services/NotificationService";
import {BusinessController} from "@/controllers/business/BusinessController";
import CustomForm from "@/components/forms/CustomForm.vue";
import CustomModal from "@/components/modals/CustomModal.vue";
import {BUSINESS_TYPE_ENUM} from "@/models/businessTypeEnum";
import {possibleBusinessStatusSelect} from "@/utils";
import CustomTextAreaField from "@/components/forms/customs/customTextAreaField/CustomTextAreaField.vue";
library.add(faXmark);
library.add(faPlus);
library.add(faCheck);
library.add(faUpLong);
library.add(faExclamation);

  export default {
      name: 'EditBusinessModal',
      props: ['callback'],
      components: {CustomTextAreaField, CustomModal, CustomForm, CustomSelectField, CustomTextField, CustomButton},
      data() {
          return {
            business: undefined,
            inputs: {
              number: {label: 'N°', value: 1, type: 'number', required: true},
              addressPro: {label: 'Address pro', value: '', required: true},
              tvaNumber: {label: 'Numéro de TVA', value: '', type: 'text'},
              tva: {label: 'TVA', value: 20, type: 'number'},
              bank: {label: 'Coordonnées bancaires', value: ''},
              status: {label: 'Status', value: '', type: 'select', selectValues: [], required: true},
              type: {label: 'Type', value: '', type: 'select', selectValues: [], required: true},
              delay: {label: 'Délai de paiement', value: '', type: 'select', selectValues: [{label:"45", value: 45}, {label:"30", value: 30}, {label:"15", value: 15}]},
              client: {label: 'Client', value: '...', type: 'select', selectValues: [{label:"TODO", value: "TODO"}], required: true},
            },
            buttons:{
              submit: {text: "Valider"}
            },
            isTerminateButtonDisplayed: false
          };
      },
      watch: {
        "inputs.type.value"(newVal) {
          const isInvoice = newVal === BUSINESS_TYPE_ENUM.INVOICE.value;
          const isInitiallyQuote = this.business.type === BUSINESS_TYPE_ENUM.QUOTE.value;
          const isInitiallyInvoice = this.business.type === BUSINESS_TYPE_ENUM.INVOICE.value;
          this.inputs.delay.readOnly = isInvoice;
          this.inputs.bank.readOnly = isInvoice;
          this.inputs.tvaNumber.readOnly = isInvoice;
          this.inputs.addressPro.readOnly = isInvoice;
          this.inputs.number.readOnly = isInvoice;
          this.inputs.tva.readOnly = isInvoice;
          this.inputs.client.readOnly = isInvoice;

          if(isInitiallyQuote && isInvoice) {
            this.inputs.status.readOnly = isInvoice;
            if (isInvoice) {
              this.inputs.status.selectValues = [BUSINESS_STATUS_ENUM.READY_TO_SEND];
              this.$refs.statusSelect.selectThis(BUSINESS_STATUS_ENUM.READY_TO_SEND.value);
            }
          }

          this.inputs.type.readOnly = isInitiallyInvoice;
        },
      },
      methods: {
        openModal(business) {
          this.$refs.modal.openModal();
          this.$refs.form.startLoading();
          this.business = business;
          this.buttons.submit.disabled = false;
          this.inputs.number.value = business.number;
          this.inputs.addressPro.value = business.addressPro;
          this.inputs.tvaNumber.value = business.tvaNumber;
          this.inputs.tva.value = business.tva;
          this.inputs.bank.value = business.bank;
          this.inputs.status.value = business.status;
          this.inputs.type.value = business.type;
          this.inputs.delay.value = business.delay;
          this.inputs.status.selectValues = possibleBusinessStatusSelect(business.status, business.type);
          this.fillTypeSelect(business.type);
          this.fillClientSelect(business.clientId);
          this.setStatusReadOnly(business.status);
          this.$refs.form?.stopLoading();
        },
        closeModal(event) {
          this.$refs.modal?.closeModal(event);
        },
        terminate(){
          this.closeModal(true);
          this.callback(this.haveToCloseEditElementsBusinessModal());
        },
        haveToCloseEditElementsBusinessModal(){
          return !(this.inputs.type.value === BUSINESS_TYPE_ENUM.QUOTE.value
              && (this.inputs.status.value === BUSINESS_STATUS_ENUM.READY_TO_SEND.value
                  || this.inputs.status.value === BUSINESS_STATUS_ENUM.CREATION.value));
        },
        async fillTypeSelect(type){
          const values = [BUSINESS_TYPE_ENUM.INVOICE];
          // An invoice can't be reset to a quote, so we propose quote only for quote
          if(type === BUSINESS_TYPE_ENUM.QUOTE.value)
              values.push(BUSINESS_TYPE_ENUM.QUOTE);
          this.inputs.type.selectValues = values;
        },
        async fillClientSelect(clientId){
          // Get the table data
          this.inputs.client.selectValues = [];
          const resp = await ClientController.getAll();
          if(!resp.errors){
            for(let i=0; i < resp.length ;i++){
              this.inputs.client.selectValues.push({label:resp[i].name, value: resp[i].id});
            }
          }
          this.inputs.client.value = clientId;
        },
        setStatusReadOnly(){
          this.inputs.status.readOnly = false;
        },
        async submitForm() {
          const res = await this.$refs.form.submitForm(() => BusinessController.patch(this.business.id,{
            clientId: this.inputs.client.value,
            number: this.inputs.number.value,
            addressPro: this.inputs.addressPro.value,
            tva: this.inputs.tva.value,
            tvaNumber: this.inputs.tvaNumber.value,
            delay: this.inputs.delay.value,
            bank: this.inputs.bank.value,
            status: this.inputs.status.value,
            type: this.inputs.type.value
          }));
          if (!res.errors) {
            (new NotificationService).addNotification("success", "Contrat modifié");
            this.terminate();
          }
        }
      }
  }

</script>

<style lang="stylus" scoped>
    @require '~/src/common/style/common.styl';
    .tva-container
      display flex
      .numeroTVA
        margin-right 1em
      .TVA
        width 5em

    .separator
      margin-top 15px
      height 2px
      background-color THEME_COLOR_PRIMARY_FADDED
      opacity 0.6

    .state-container
      display flex
      margin-top 30px
      .separator-state-container
        width 30px
</style>
