/**
 * Permet de gérer tout ce qui est lié à l'utilisateur
 */
export class NotificationService {

    callBackReload = undefined;

    /**
     * Setup for make this class a Singleton
     */
    constructor() {
        if (NotificationService.exists) {
            return NotificationService.instance;
        }
        NotificationService.instance = this;
        NotificationService.exists = true;

        this.callBackReload = function (){console.log("callBackReload called, but callback not set yet !");}
        this.reloadSuppression();

        return this;
    }

    reloadSuppression(){
        // If there is a notification list
        if(localStorage.notifications){
            const currentDate = new Date();
            // Retrieve the list
            let notifications = JSON.parse(localStorage.notifications);
            for(let i=0 ; i < notifications.length ; i++){
                const timeleft = (currentDate - new Date(notifications[i].date));
                if(timeleft >= 15000){
                    // Remove notification now
                    this.removeNotification(notifications[i].id);
                }else{
                    // Remove notification later
                    setTimeout(() => {
                        this.removeNotification(notifications[i].id)
                    }, 15000-timeleft);
                }
            }
        }
    }

    addNotification(type, texte) {
        // Create the list if not exist
        if(!localStorage.notifications){
            localStorage.notifications = JSON.stringify([]);
        }
        // Retrieve the list
        let notifications = JSON.parse(localStorage.notifications);
        // Search id available
        const Ids = notifications.map(e => e.id);
        let availableId = 0;
        let i = 0;
        while(availableId === 0){
            if(Ids.indexOf(i) === -1){
                availableId = i;
            }
            i++;
        }
        // Add notif on first index
        notifications.push({text:texte, type: type, id: availableId, date: new Date()});
        // Reaorder for set the previous notification at first place
        for (let i = 0; i < notifications.length; i++) { notifications[i].index = i+1 }
        // Save on local storage
        localStorage.notifications = JSON.stringify(notifications);
        // Call the reload call back for force the notification refresh in front
        this.callBackReload();
        setTimeout(() => {
            this.removeNotification(availableId)
        }, 15000);
    }

    removeNotification(id) {
        if(localStorage.notifications) {
            // Retrieve the list
            let notifications = JSON.parse(localStorage.notifications);
            // Get the index the notification to remove
            const index = notifications.map(e => e.id).indexOf(id);
            // case of remove all
            if (notifications.length === 1) {
                notifications = []
            }
            // Remove the notification
            else {
                if (index > -1) {
                    notifications.splice(index, 1)
                }
            }
            // Reorder the array
            for (let i = 0; i < notifications.length; i++) {
                notifications[i].index = i
            }
            // Save it on local storage
            localStorage.notifications = JSON.stringify(notifications);
        }
        // Call the reload call back for force the notification refresh in front
        this.callBackReload();
    }

    static getNotification(){
        return localStorage.notifications ? JSON.parse(localStorage.notifications) : [];
    }

    setCallBackReload(callBack){
        this.callBackReload = callBack
    }

}
