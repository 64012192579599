<template>
  <div v-if="loading" class="loader">
    <div class="loader-background">
      <span class="fas fa-lemon"/>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLemon } from "@fortawesome/free-solid-svg-icons";
library.add(faLemon);

/* eslint-disable vue/no-mutating-props */
export default {
  name: 'CustomLoader',
  data() {
    return {
      loading: false
    };
  },
  methods: {
    startLoading(){
      this.loading = true;
    },
    stopLoading(){
      this.loading = false;
    }
  }
}

</script>

<style lang="stylus" scoped>
@import '~/src/common/style/common.styl'

/* /!\ Mettre le container parent en postion relative */

.loader
  position absolute
  z-index 1999
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.1)
  border-radius 10px
  box-shadow 0 1px 15px 10px rgba(0, 0, 0, 0.1)
  display flex
  justify-content center
  align-items center
  svg
    height 2em
    width 2em
  .loader-background
    display flex
    align-items center
    justify-content center
    width 40px
    height 40px
    background-color rgba(255, 255, 255, 0.8)
    border-radius 15px

@keyframes ckw
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

.fa-lemon
  height 60%
  color THEME_COLOR_PRIMARY !important
  opacity 0.6
  animation-name ckw
  animation-duration 1s
  animation-iteration-count infinite
  transform-origin 50% 50%
</style>
