import {Controller} from "@/controllers/Controller";

/**
 * Controller for group API
 */
export class CollectionController extends Controller {

     static getAll(businessId, urlParams) {
         return this.requestBuilder("GET",`/business/collections/by-business/${businessId}?${urlParams}`, true);
     }

     static post(body) {
         return this.requestBuilder("POST",`/business/collections`, true, body);
     }

}
