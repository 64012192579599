<template>
  <div class="global-container">
    <h1>Qui se cache derrière Citron Digital ?</h1>
    <h2>Un hommme</h2>
<!--      <p>Mettre mon visage</p>-->
    <p>
      Bonjour, je m'appelle Franck Besson un développeur logiciel spécialisé dans le développement de solutions pour des
      clients tels qu'Allianz, Renault ou encore MarLink. Fort de nombreuses années d'expérience, j'ai décidé de créer
      Citron Digital en 2020, une entreprise dédiée à fournir des outils innovants pour simplifier la gestion des entreprises.
    </p>
    <h2>Une mission</h2>
    <p>
      Chez Citron Digital, notre mission est de libérer les entrepreneurs des tâches fastidieuses liées à la gestion
      quotidienne de leur entreprise. Notre objectif est de permettre à nos clients de se concentrer pleinement sur le
      développement et l'épanouissement de leur entreprise. Nous proposons des outils et des services adaptés pour
      simplifier les processus, optimiser les performances et offrir une expérience utilisateur optimale.
    </p>

    <p>
      Ne perdez plus votre temps à gérer votre entreprise, concentrez-vous plutôt sur son développement et son épanouissement.
      Avec Citron Digital, nous sommes là pour vous accompagner dans cette démarche.
    </p>

  </div>
</template>

<script>
    export default {
        name: 'WhoView',
    }
</script>

<style lang="stylus" scoped>
  @require '~/src/common/style/common.styl';
  h1
   text-align center
  .global-container
    padding-left 50px
    padding-right 50px
</style>